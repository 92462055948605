import { Avatar, Col, Flex, Icons, Row, Typography, useTheme } from '@jcm/design-system';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useParticipacoesDadosPessoaisQuery } from 'queries/participante';
import { DadosCadastraisSection } from '../../../cards';
import { useSkeletonHelper } from 'hooks';
import { DateFormatter } from 'lib';

export const InformacoesPessoais: React.FC = () => {
	const { colors } = useTheme();

	// Extrair id do participante da URL
	const params = useParams<{ participacaoId: string }>();
	const { data, isPending } = useParticipacoesDadosPessoaisQuery(params.participacaoId as string);
	const { getValueOrSkeleton } = useSkeletonHelper({ isPending });

	return (
		<DadosCadastraisSection title='Informações Pessoais'>
			<Row style={{ gap: 12, alignItems: 'center' }}>
				<Avatar style={{ backgroundColor: colors.tertiary }} size={'large'}>
					<Icons.Person style={{ fontSize: 40, color: colors.onTertiary }} />
				</Avatar>
				<Flex vertical style={{ gap: 4 }}>
					<Typography.Body>{getValueOrSkeleton(data?.nome)}</Typography.Body>
					<Typography.Body>{getValueOrSkeleton(data?.cpf)}</Typography.Body>
				</Flex>
			</Row>
			<Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
				<Col xs={24} md={6}>
					<Flex vertical style={{ gap: 4 }}>
						<Typography.Label size='large' style={{ color: colors.tertiary }}>
							Data de nascimento
						</Typography.Label>
						<Typography.Body>
							{getValueOrSkeleton(DateFormatter.format(DateFormatter.toDate(data?.nascimento)))}
						</Typography.Body>
					</Flex>
				</Col>
				<Col xs={24} md={4}>
					<Flex vertical style={{ gap: 4 }}>
						<Typography.Label size='large' style={{ color: colors.tertiary }}>
							Sexo
						</Typography.Label>
						<Typography.Body>{getValueOrSkeleton(data?.tipoSexo)}</Typography.Body>
					</Flex>
				</Col>
				<Col xs={24} md={4}>
					<Flex vertical style={{ gap: 4 }}>
						<Typography.Label size='large' style={{ color: colors.tertiary }}>
							Estado Civil
						</Typography.Label>
						<Typography.Body>{getValueOrSkeleton(data?.estadoCivil)}</Typography.Body>
					</Flex>
				</Col>
				<Col xs={24} md={4}>
					<Flex vertical style={{ gap: 4 }}>
						<Typography.Label size='large' style={{ color: colors.tertiary }}>
							Naturalidade
						</Typography.Label>
						<Typography.Body>{getValueOrSkeleton(data?.naturalidade)}</Typography.Body>
					</Flex>
				</Col>
				<Col xs={24} md={4}>
					<Flex vertical style={{ gap: 4 }}>
						<Typography.Label size='large' style={{ color: colors.tertiary }}>
							Nacionalidade
						</Typography.Label>
						<Typography.Body>{getValueOrSkeleton(data?.nacionalidade)}</Typography.Body>
					</Flex>
				</Col>
			</Row>
			<Row style={{ alignItems: 'center' }}>
				<Col xs={24} md={12}>
					<Flex vertical style={{ gap: 4 }}>
						<Typography.Label size='large' style={{ color: colors.tertiary }}>
							Nome da mãe
						</Typography.Label>
						<Typography.Body>{getValueOrSkeleton(data?.nomeMae)}</Typography.Body>
					</Flex>
				</Col>
				<Col xs={24} md={12}>
					<Flex vertical style={{ gap: 4 }}>
						<Typography.Label size='large' style={{ color: colors.tertiary }}>
							Nome do pai
						</Typography.Label>
						<Typography.Body>{getValueOrSkeleton(data?.nomePai)}</Typography.Body>
					</Flex>
				</Col>
			</Row>
		</DadosCadastraisSection>
	);
};
