import { ITableProps, Typography, VariantType, useTheme } from '@jcm/design-system';
import { nanoid } from 'nanoid';
import { memo } from 'react';

import { CustomTitleTable } from 'components';
import { useThemeContext } from 'context';
import { NumberFormatter } from 'lib';

import styles from './index.module.scss';

export type ContribuicaoType = {
	data: Date | string;
	indice: string;
	conta: string;
	tipo: string;
	evento: string;
	valor: number;
	valorQuota: number | null;
	quotas: number | null;
};
type TableType = ITableProps<ContribuicaoType>;

export const columns: TableType['columns'] = [
	{
		title: 'Data',
		key: 'data',
		dataIndex: 'data',
		render: (value: Date | string) => {
			const dataConvertida = new Date(value);

			if (dataConvertida.toString() === 'Invalid Date') return value.toString();

			return dataConvertida.toLocaleDateString();
		},
	},
	{ title: 'Índice', key: 'indice', dataIndex: 'indice' },
	{ title: 'Conta', key: 'conta', dataIndex: 'conta' },
	{ title: 'Tipo', key: 'tipo', dataIndex: 'tipo' },
	{ title: 'Evento', key: 'evento', dataIndex: 'evento' },
	{
		title: 'Valor',
		key: 'valor',
		dataIndex: 'valor',
		render: (value: number) => NumberFormatter.format(value, 'BRL'),
	},

	{
		title: 'Cotas',
		key: 'cotas',
		dataIndex: 'quotas',
	},

	{
		title: 'Valor da Cota',
		key: 'valorCota',
		dataIndex: 'valorQuota',
		// Valor da cota não é uma unidade monetária
		render: (value: number | null) => (value ? value.toFixed(8) : ''),
	},
];

export type TabelaExtratoMovimentacaoProps = {
	title: string;
	variant: VariantType;
	data?: ContribuicaoType[];
	total?: number;
};
export const TabelaExtratoMovimentacao = memo<TabelaExtratoMovimentacaoProps>(({ title, data, variant, total }) => {
	const { currentTheme } = useThemeContext();
	const { colors } = useTheme();

	const hasData = !!data && total !== undefined;
	const dataSource: TableType['dataSource'] = hasData
		? [
				...data,
				{
					data: 'Total de Movimento',
					indice: '',
					conta: '',
					tipo: '',
					evento: '',
					valor: total,
					valorQuota: null,
					quotas: null,
				},
			]
		: [];

	const tableRowClassName: TableType['rowClassName'] = (record) => {
		const classNames: string[] = [styles.linha];

		if (record.data === 'Total de Movimento') {
			classNames.push(styles.trTotalSecondary);
		} else {
			classNames.push(styles.trNormal);
		}

		return classNames.join(' ');
	};

	return (
		<CustomTitleTable<ContribuicaoType>
			tableVariant={variant}
			containerClassName={currentTheme === 'light' ? styles.lightTableContainer : styles.darkTableContainer}
			headerStyle={{ backgroundColor: colors.secondary, color: colors.onSecondary }}
			columns={columns}
			dataSource={dataSource}
			rowKey={() => nanoid()}
			rowClassName={tableRowClassName}
			title={
				<Typography.Label variant='default' size='large' style={{ padding: '0.25rem 1rem', fontWeight: 500 }}>
					{title}
				</Typography.Label>
			}
		/>
	);
});
