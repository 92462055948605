import { Button, Icons } from '@jcm/design-system';
import { memo, useMemo } from 'react';

import { useThemeContext } from 'context';

export const ToggleThemeIconButton = memo<{ id?: string }>(({ id }) => {
	const { currentTheme, toggleTheme } = useThemeContext();

	const icon = useMemo(() => {
		return currentTheme === 'light' ? (
			<Icons.Sunny variant='outlined' fill />
		) : (
			<Icons.DarkMode variant='outlined' fill />
		);
	}, [currentTheme]);

	return <Button variant='default' type='text' icon={icon} onClick={toggleTheme} id={id} circle />;
});
