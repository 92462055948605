import { Button, Col, Collapse, Flex, ICollapseProps, Row, Typography, useTheme } from '@jcm/design-system';
import { nanoid } from 'nanoid';

import avestruz from 'assets/images/avestruz.svg';
import styles from './ErrorComponent.module.scss';

type ErrorDetailsProps<T extends Error> = { error: T };

const ErrorDetails = <T extends Error>({ error }: ErrorDetailsProps<T>) => {
	const { shapes } = useTheme();

	const items: ICollapseProps['items'] = [
		{
			label: 'Detalhes',
			children: (
				<Flex gap={shapes.sizes.large} vertical style={{ textAlign: 'start' }}>
					<Typography.Body variant='error'>{error.message}</Typography.Body>

					<Flex vertical>
						{error.stack?.split('\n')?.map((line) => {
							return (
								<Typography.Body key={nanoid()} size='small' style={{ wordWrap: 'break-word' }}>
									{line}
								</Typography.Body>
							);
						})}
					</Flex>
				</Flex>
			),
		},
	];

	return <Collapse className={styles.collapse} expandIconPosition='end' variant='default' items={items} />;
};

export type ErrorComponentProps<T extends Error = Error> = {
	id?: string;
	loading?: boolean;
	error?: T;
	onClick?: () => void;
};
export const ErrorComponent = <T extends Error>({ onClick, id, loading, error }: ErrorComponentProps<T>) => {
	return (
		<Row id={id} justify='center' align='middle' style={{ height: '100%', padding: '1rem' }} gutter={[16, 16]}>
			<Col span={24} style={{ textAlign: 'center' }}>
				<Typography.Title variant='error' size='medium'>
					Algo deu errado!
				</Typography.Title>
			</Col>

			<Col span={24} style={{ textAlign: 'center' }}>
				<img src={avestruz} alt='' id='img-avestruz' style={{ height: 200 }} />
			</Col>

			<Col span={24} style={{ textAlign: 'center' }}>
				{onClick && (
					<Button variant='default' type='filled-tonal' onClick={onClick} id='btn-tentar-novamente' loading={loading}>
						Tentar novamente
					</Button>
				)}
			</Col>

			<Col span={24} style={{ textAlign: 'center' }}>
				{error && <ErrorDetails error={error} />}
			</Col>
		</Row>
	);
};
