import { Avatar, Button, Card, Flex, Icons, Typography, useTheme } from '@jcm/design-system';
import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useKeycloakContext } from 'context';

import styles from './index.module.scss';

type AvatarPopoverProps = {
	children: React.ReactNode;
	isOpen: boolean;
	onOpenChange: (newValue: boolean) => void;
	placement?: PopoverProps['placement'];
};
const AvatarPopover = memo<AvatarPopoverProps>(({ children, isOpen, onOpenChange, placement }) => {
	const navigate = useNavigate();

	const { user, login, logout } = useKeycloakContext();
	const { colors, shapes } = useTheme();

	const doNewLogin = async () => {
		await logout();
		await login({ loginHint: '' });
	};

	const doLogout = async () => {
		navigate('/');
		await logout();
	};

	const cardStyle: React.CSSProperties = {
		padding: shapes.sizes.large,
		borderStartStartRadius: 0,
		borderStartEndRadius: 0,
	};

	const content = (
		<Flex gap={shapes.sizes.medium} vertical>
			<Flex gap={shapes.sizes.extraSmall} vertical>
				<Card id='avatar-popover-content-card' variant='default' style={cardStyle}>
					<Flex gap={shapes.sizes.large} style={{ width: '100%' }} vertical>
						<Flex align='center' gap={shapes.sizes.medium}>
							<Avatar
								size='large'
								className={styles.avatar}
								style={{
									backgroundColor: colors.secondaryContainer,
									color: colors.onSecondaryContainer,
								}}
							>
								{user?.name
									? user?.name.slice(0, 2).toLocaleUpperCase()
									: user?.preferred_username.slice(0, 2).toLocaleUpperCase()}
							</Avatar>
							<Flex gap='0.25rem' vertical>
								<Typography.Body variant='default' size='large'>
									{user?.name ?? user?.preferred_username ?? 'Usuário'}
								</Typography.Body>
								<Typography.Body variant='default' size='small'>
									{user?.email ?? 'usuario@email.com'}
								</Typography.Body>
							</Flex>
						</Flex>
					</Flex>
				</Card>

				<Card id='avatar-popover-content-card' variant='default' style={cardStyle}>
					<Button
						id='adicionar-conta-btn'
						type='text'
						variant='default'
						icon={<Icons.PersonAdd variant='outlined' style={{ fontSize: '24px' }} />}
						style={{ width: '100%' }}
						onClick={doNewLogin}
					>
						Adicionar conta
					</Button>
				</Card>
			</Flex>

			<Flex style={{ padding: `0 ${shapes.sizes.large}` }}>
				<Button
					id='logout-btn'
					type='text'
					variant='default'
					icon={<Icons.Logout variant='outlined' style={{ fontSize: '24px' }} />}
					style={{ width: '100%' }}
					onClick={doLogout}
				>
					Sair
				</Button>
			</Flex>
		</Flex>
	);

	return (
		<Popover
			overlayClassName={styles.userPopover}
			color={colors.secondaryContainer}
			placement={placement}
			trigger='click'
			content={content}
			open={isOpen}
			onOpenChange={onOpenChange}
		>
			{children}
		</Popover>
	);
});

type UserAvatarProps = { nome?: string; status?: string; placement?: PopoverProps['placement'] };
export const UserAvatar = memo<UserAvatarProps>(({ nome, status, placement = 'rightBottom' }) => {
	const { colors, shapes } = useTheme();

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	return (
		<AvatarPopover isOpen={isPopoverOpen} onOpenChange={(newValue) => setIsPopoverOpen(newValue)} placement={placement}>
			<Flex align='flex-start' justify='start' gap={shapes.sizes.small}>
				<Avatar
					size='large'
					className={styles.avatar}
					style={{
						backgroundColor: colors.secondaryContainer,
						color: colors.onSecondaryContainer,
						fontSize: '40px',
					}}
				>
					<Icons.Person variant='outlined' />
				</Avatar>
				<Flex gap={shapes.sizes.extraSmall} vertical style={{ userSelect: 'none', color: colors.onSurfaceVariant }}>
					<Typography.Body variant='primary' size='large' style={{ fontWeight: 500 }}>
						{nome}
					</Typography.Body>
					<Typography.Body size='large'>{status}</Typography.Body>
				</Flex>
			</Flex>
		</AvatarPopover>
	);
});
