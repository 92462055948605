import { Col, Row, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { ParticipacoesDadosContatoEmail } from 'api';
import { useParticipacoesContatoQuery } from 'queries/participante';

import { DadosCadastraisSection } from '../../../cards';

const Email = memo<{ dadosEmail: ParticipacoesDadosContatoEmail; index: number }>(({ dadosEmail, index }) => {
	const { colors } = useTheme();

	return (
		<>
			<Typography.Title size='small' strong style={{ color: colors.tertiary }}>
				Email {index + 1}
			</Typography.Title>

			<Row gutter={[16, 16]} style={{ justifyContent: 'space-between', maxWidth: 500 }}>
				<Col xs={24} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
					<Typography.Body>{dadosEmail.email}</Typography.Body>
				</Col>
			</Row>
		</>
	);
});
export const Emails: React.FC = () => {
	// Extrair id do participante da URL
	const params = useParams<{ participacaoId: string }>();
	const { data } = useParticipacoesContatoQuery(params.participacaoId as string);

	return (
		<DadosCadastraisSection title='E-mail'>
			<Row gutter={[16, 16]}>
				{data?.emails.map((dadosEmail, index) => (
					<Col key={dadosEmail.id} xs={24} md={12} style={{ gap: 250 }}>
						<Email dadosEmail={dadosEmail} index={index} />
					</Col>
				))}
			</Row>
		</DadosCadastraisSection>
	);
};
