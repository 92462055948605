import { Col, Row, Typography, useTheme } from '@jcm/design-system';
import { nanoid } from 'nanoid';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { GetParticipacoesDadosContaBancariaReturnType } from 'api';
import { useSkeletonHelper } from 'hooks';
import { useParticipacoesDadosContaBancariaQuery } from 'queries/participante';

import { DadosCadastraisSection } from '../../../cards';

const Conta = memo<{ dadosConta: GetParticipacoesDadosContaBancariaReturnType; index: number }>(
	({ dadosConta, index }) => {
		const { colors } = useTheme();
		const params = useParams<{ participacaoId: string }>();
		const { isPending } = useParticipacoesDadosContaBancariaQuery(params.participacaoId as string);
		const { getValueOrSkeleton } = useSkeletonHelper({ isPending });
		return (
			<>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
					<Typography.Title size='small' strong style={{ color: colors.tertiary }}>
						Conta bancária {index + 1}
					</Typography.Title>

					<Row gutter={[16, 16]} style={{ justifyContent: 'space-between' }}>
						<Col xs={12} xxl={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Banco
							</Typography.Label>
							<Typography.Body>{getValueOrSkeleton(dadosConta.banco)}</Typography.Body>
						</Col>

						<Col xs={12} xxl={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Agência
							</Typography.Label>
							<Typography.Body>{getValueOrSkeleton(dadosConta.agencia)}</Typography.Body>
						</Col>
						<Col xs={12} xxl={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Conta corrente
							</Typography.Label>
							<Typography.Body>{getValueOrSkeleton(dadosConta.contaCorrente)}</Typography.Body>
						</Col>
						<Col xs={12} xxl={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Dígito
							</Typography.Label>
							<Typography.Body>{getValueOrSkeleton(dadosConta.digitoVerificadorContaCorrente)}</Typography.Body>
						</Col>
					</Row>
				</div>
			</>
		);
	},
);
export const ContasBancarias: React.FC = () => {
	// Extrair id do participante da URL
	const params = useParams<{ participacaoId: string }>();
	const { data } = useParticipacoesDadosContaBancariaQuery(params.participacaoId as string);

	return (
		<DadosCadastraisSection title='Contas bancárias'>
			<Row gutter={[16, 16]}>
				{data?.map((dadosConta, index) => (
					<Col key={nanoid()} xs={24} md={12} style={{ gap: 250 }}>
						<Conta dadosConta={dadosConta} index={index} />
					</Col>
				))}
			</Row>
		</DadosCadastraisSection>
	);
};
