import { Dispatch, useEffect, useReducer } from 'react';

export type ArrayReducerActionType<TData = unknown> =
	| { type: 'add'; value: TData }
	| { type: 'remove'; findFn: (value: TData, index: number, array: TData[]) => boolean }
	| {
			type: 'update';
			value: Partial<TData>;
			findFn: (value: TData, index: number, array: TData[] | readonly TData[]) => boolean;
	  }
	| { type: 'validate' | 'save' | 'reset' };
export type ArrayReducerDispatch<TData> = Dispatch<ArrayReducerActionType<TData>>;

export type ArrayReducerOptions<TData = unknown> = {
	onAdd?: (data: TData) => void;
	onValidate?: (data: TData[]) => void;
	onSave?: (data: TData[]) => void;
	onReset?: () => void;
};
export type ArrayReducerReturnType<TData = unknown> = [TData[] | undefined, ArrayReducerDispatch<TData>];

/**
 * Hook feito pra abstrair a lógica de manuseio de dados de um formulário,
 * com métodos para atualizar os dados, resetar aos valores iniciais, validar o formulário e salvar.
 *
 * @param initialData Array que contém os dados do formulário.
 * @param options Opções e callbacks, como `onSave` e `onValidate`
 * @returns Mesmo retorno de `useReducer`
 */
export const useArrayFormReducer = <TData>(initialData?: TData[], options?: ArrayReducerOptions<TData>) => {
	const reducer = (state: TData[] | undefined, action: ArrayReducerActionType<TData>) => {
		let newState: TData[];

		switch (action.type) {
			case 'add': {
				newState = [...(state || []), action.value];
				options?.onAdd?.(action.value);
				return newState;
			}

			case 'update': {
				newState = [...(state || [])];
				const index = newState.findIndex(action.findFn);

				if (index !== undefined && index >= 0 && state) {
					const item = newState[index];
					newState[index] = { ...item, ...action.value };
				}

				return newState;
			}

			case 'remove': {
				newState = state?.filter((item, index, array) => !action.findFn(item, index, array)) || [];
				return newState;
			}

			case 'validate': {
				options?.onValidate?.(state || []);
				return state;
			}

			case 'reset': {
				options?.onReset?.();
				return initialData;
			}

			case 'save': {
				options?.onSave?.(state || []);
				return state;
			}
		}
	};

	const [state, dispatch] = useReducer(reducer, initialData);

	// Qualquer alteração nos dados iniciais gera um evento "reset"
	// Dessa forma, um `refetch` na query de origem dos dados vai gerar um update nan interface
	useEffect(() => {
		if (!initialData) return;
		dispatch({ type: 'reset' });
	}, [initialData]);

	// É necessário "castar" o tipo, já que retornamos um array [valor, outroValor]
	// Comportamento padrão do TS é considerar esse retorno como Array<valor | outroValor>
	return [state, dispatch] as ArrayReducerReturnType<TData>;
};
