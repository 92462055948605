import { Button, Icons } from '@jcm/design-system';
import { memo } from 'react';

import { useVisibilityContext } from 'context';

interface IToggleVisibilityButton {
	loading?: boolean;
}
export const ToggleVisibilityButton = memo<IToggleVisibilityButton>(({ loading }) => {
	const { isVisible, toggleVisibility } = useVisibilityContext();

	const Icon = isVisible ? Icons.Visibility : Icons.VisibilityOff;

	return <Button variant='default' type='text' onClick={toggleVisibility} icon={<Icon />} loading={loading} circle />;
});
