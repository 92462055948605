import { Button, ContentLayout, DrawerBar, DrawerBarItem, Icons, Surface } from '@jcm/design-system';
import { useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export const LayoutHome = () => {
	const navigate = useNavigate();

	const drawerBarItems = useMemo<DrawerBarItem[]>(() => {
		return [{ key: '/', label: 'Página Inicial', icon: <Icons.Home variant='outlined' /> }];
	}, []);

	return (
		<ContentLayout
			sider={<DrawerBar items={drawerBarItems} />}
			headerProps={{
				icons: (
					<>
						<Button variant='default' type='filled' onClick={() => navigate('/admin')}>
							Acessar Portal Administrativo
						</Button>
						<Button variant='default' type='filled' onClick={() => navigate('/participante')}>
							Acessar Portal do Participante
						</Button>
					</>
				),
			}}
		>
			<Surface>
				<Outlet />
			</Surface>
		</ContentLayout>
	);
};
