import { Flex, Surface, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import participantes from 'assets/images/participantes.svg';

export type SemItensProps = {
	id?: string;
	className?: string;
};

export const SemItens = memo<SemItensProps>(({ ...props }) => {
	const { colors } = useTheme();

	return (
		<Surface
			variant='bright'
			level={1}
			style={{ minHeight: 170, padding: '1rem', textAlign: 'center', width: 455 }}
			{...props}
		>
			<Flex vertical gap={'0.5rem'} align='center'>
				<Typography.Body variant='default' size='medium' style={{ color: colors.onSurfaceVariant }}>
					Use a barra de pesquisa acima para encontrar participantes
				</Typography.Body>
				<img src={participantes} alt='' id='img-avestruz' style={{ width: 155 }} />
			</Flex>
		</Surface>
	);
});
