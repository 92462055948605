import { Container, Flex, IContainerProps, Skeleton, Typography, useTheme } from '@jcm/design-system';
import { memo, useCallback, useEffect, useRef } from 'react';

import { useParticipanteIdentityContext } from 'context';
import { useParticipacoesPlanosQuery } from 'queries/participante';

import { ISeletorParticipacaoProps } from '.';

interface ISeletorParticipacaoCardProps {
	active?: boolean;

	style?: React.CSSProperties;
	children?: React.ReactNode;
	onClick?: IContainerProps['onClick'];
}
const SeletorParticipacaoMobileCard = memo<ISeletorParticipacaoCardProps>(({ active, style, children, onClick }) => {
	const { colors, shapes } = useTheme();

	const ref = useRef<HTMLElement>(null);

	const scrollIntoView = useCallback(() => {
		ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
	}, []);

	useEffect(() => {
		if (active) scrollIntoView();
	}, [active, scrollIntoView]);

	return (
		<Container
			elevation='lowest'
			variant='default'
			style={{
				padding: shapes.sizes.small,
				borderRadius: shapes.sizes.medium,
				border: `1px solid ${active ? colors.primary : 'transparent'}`,
				...style,
			}}
			onClick={(e) => {
				if (onClick) {
					onClick(e);
					ref.current?.scrollIntoView?.({ behavior: 'smooth', block: 'end', inline: 'end' });
				}
			}}
		>
			<Flex gap={shapes.sizes.extraSmall} ref={ref} vertical>
				{children}
			</Flex>
		</Container>
	);
});

interface ISeletorParticipacaoMobileProps extends ISeletorParticipacaoProps {}
export const SeletorParticipacaoMobile = memo<ISeletorParticipacaoMobileProps>(
	({ idParticipacaoAtiva, onParticipacaoChange, ...props }) => {
		const { shapes } = useTheme();

		const { idParticipacao } = useParticipanteIdentityContext();

		const { data, isPending } = useParticipacoesPlanosQuery(idParticipacao);

		return (
			<Flex
				gap={shapes.sizes.small}
				style={{ minWidth: '100vw', overflow: 'scroll', padding: `${shapes.sizes.small} ${shapes.sizes.large}` }}
				{...props}
			>
				{isPending && (
					<SeletorParticipacaoMobileCard>
						<Skeleton.Input active />
					</SeletorParticipacaoMobileCard>
				)}
				{data?.map((item, _, arr) => {
					return (
						<SeletorParticipacaoMobileCard
							key={item.participacaoId}
							style={{ minWidth: arr.length === 1 ? '100%' : '80vw' }}
							onClick={() => onParticipacaoChange(item.participacaoId)}
							active={idParticipacaoAtiva === item.participacaoId}
						>
							<Typography.Body size='small' style={{ fontWeight: 600 }}>
								Plano
							</Typography.Body>
							<Typography.Body variant='primary'>{item.plano}</Typography.Body>
						</SeletorParticipacaoMobileCard>
					);
				})}
			</Flex>
		);
	},
);
