import { Flex, Icons, Typography, useTheme } from '@jcm/design-system';

export const TotalPatrocinadora = () => {
	const { colors, shapes } = useTheme();
	return (
		<Flex
			style={{
				alignItems: 'center',
				gap: shapes.sizes.large,
			}}
		>
			<Flex
				style={{
					backgroundColor: colors.magentaContainer,
					padding: shapes.sizes.small,
					borderRadius: shapes.sizes.small,
					justifyContent: 'center',
					alignItems: 'center',
					height: 'fit-content',
				}}
			>
				<Typography.Body
					style={{
						fontSize: 20,
						color: colors.magenta,
					}}
				>
					<Icons.CorporateFare />
				</Typography.Body>
			</Flex>
			<Flex
				vertical
				style={{
					gap: '0.5rem',
				}}
			>
				<Typography.Body>Total de patrocinadora</Typography.Body>
				<Flex>
					<Typography.Title strong size='large'>
						XXX.XXX
					</Typography.Title>
				</Flex>
			</Flex>
		</Flex>
	);
};
