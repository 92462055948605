type ValidatorFn = (value: unknown) => boolean;
type GetValidatorFn<K = unknown> = (condition: K) => ValidatorFn;

export type FieldValidationResult = { isValid: boolean; message?: string };
export type ValidationResult<T> = Partial<Record<keyof T, FieldValidationResult>> & { isValid: boolean };
export type ValidationFields<T> = Partial<Record<keyof T, unknown>>;

export enum ValidatorRules {
	Required,
	MinLength,
	MaxLength,

	Email,
	AllNumerical,

	Custom,
}

export enum ValidatorMessages {
	Required = 'Campo obrigatório!',
	MinLength = 'Campo não ultrapassa o tamanho mínimo.',
	MaxLength = 'Campo excede o tamanho máximo.',

	Email = 'Endereço de e-mail inválido!',
	AllNumerical = 'Campo deve conter apenas números!',

	Invalid = 'Valor inválido!',
}

type ValidatorRule<T, K> = { name: T; condition: K; message: string };
export type ValidatorRuleConfig =
	| ValidatorRule<ValidatorRules.Required, boolean>
	| ValidatorRule<ValidatorRules.MinLength, number>
	| ValidatorRule<ValidatorRules.MaxLength, number>
	| ValidatorRule<ValidatorRules.Email, boolean>
	| ValidatorRule<ValidatorRules.AllNumerical, boolean>
	| ValidatorRule<ValidatorRules.Custom, (value: unknown) => boolean>;

type FieldType<T> = Partial<Record<keyof T, ValidatorRuleConfig[]>>;

/**
 * Classe responsável por abrigar métodos de validação de campos.
 *
 * Funções de validação retornam mensagens a serem exibidas na interface.
 */
export class Validator<T = unknown> {
	private fields: FieldType<T>;

	/**
	 * Recebe um objeto com uma relação `{campo: regras}`
	 *
	 * As primeiras regras são aplicadas PRIMEIRO, e sua mensagem tem preferência.
	 */
	constructor(fields: FieldType<T>) {
		this.fields = fields;
	}

	// Funções de validação

	private getRequiredValidatorFn: GetValidatorFn<boolean> = (condition: boolean) => (value: unknown) =>
		condition ? !!value : true;

	private getMaxLengthValidatorFn: GetValidatorFn<number> = (condition: number) => (value: unknown) => {
		if (typeof value !== 'string' && typeof value !== 'number') return false;

		return value.toString().length <= condition;
	};
	private getMinLengthValidatorFn: GetValidatorFn<number> = (condition: number) => (value: unknown) => {
		if (typeof value !== 'string' && typeof value !== 'number') return false;

		return value.toString().length >= condition;
	};

	/**
	 * Verifica se o formato de e-mail é válido, usando uma expressão regular
	 */
	private getEmailValidatorFn: GetValidatorFn<boolean> = (condition: boolean) => (value: unknown) => {
		if (!condition) return true;

		const emailRegExp =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return typeof value !== 'string' ? false : emailRegExp.test(value);
	};

	/**
	 * Verifica se o valor é inteiramente numérico, usando uma expressão regular
	 */
	private getAllNumericalValidatorFn = (condition: boolean) => (value: unknown) => {
		if (!condition) return true;
		if (typeof value !== 'string' && typeof value !== 'number') return false;

		const allNumericalRegExp = /^\d*$/;
		return allNumericalRegExp.test(value.toString());
	};

	/**
	 * Retorna a função de validação para determinada regra
	 */
	private getRuleValidatorFn = (rule: ValidatorRuleConfig): ValidatorFn => {
		switch (rule.name) {
			case ValidatorRules.Required:
				return this.getRequiredValidatorFn(rule.condition);
			// Tamanho
			case ValidatorRules.MinLength:
				return this.getMinLengthValidatorFn(rule.condition);
			case ValidatorRules.MaxLength:
				return this.getMaxLengthValidatorFn(rule.condition);
			// Expressões regulares
			case ValidatorRules.Email:
				return this.getEmailValidatorFn(rule.condition);
			case ValidatorRules.AllNumerical:
				return this.getAllNumericalValidatorFn(rule.condition);
			// Custom
			case ValidatorRules.Custom:
				return rule.condition;
		}
	};

	/**
	 * Realiza a validação de determinado campo
	 */
	public validateField = (options: { name: keyof T; value: unknown }): FieldValidationResult => {
		const messages: string[] = [];
		const fieldRules = this.fields[options.name];

		const everyFn = (rule: ValidatorRuleConfig) => {
			const validatorFn = this.getRuleValidatorFn(rule);
			const isValidatorValid = validatorFn(options.value);

			if (!isValidatorValid) messages.push(rule.message);
			return isValidatorValid;
		};

		const isValid = fieldRules ? fieldRules.every(everyFn) : false;

		// Retornamos somente a primeira mensagem
		return { isValid, message: messages[0] };
	};

	/**
	 * Realiza a validação dos campos informados
	 * @argument fields Recebe uma relação `{campo: valor}`
	 */
	public validateFields = (fields: ValidationFields<T>) => {
		const results = { isValid: true };

		const fieldArray = Object.entries(fields) as [keyof T, unknown][];

		fieldArray.forEach(([key, value]) => {
			const result = this.validateField({ name: key, value: value });

			// Construímos o objeto de resultado
			Object.defineProperty(results, key, { value: result });
			if (result.isValid === false) {
				Object.defineProperty(results, 'isValid', { value: result.isValid });
			}
		});

		return results as ValidationResult<T>;
	};
}
