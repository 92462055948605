import { Flex, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

export interface IAreaProps {
	title: string;
	children: React.ReactNode;
}

export const Area = memo<IAreaProps>((props) => {
	const { shapes } = useTheme();

	return (
		<Flex gap={shapes.sizes.large} vertical>
			<Typography.Title variant='primary' style={{ fontWeight: 600 }}>
				{props.title}
			</Typography.Title>

			<div>{props.children}</div>
		</Flex>
	);
});
