import { ITypographyProps, Typography } from '@jcm/design-system';
import { memo } from 'react';

interface IFormErrorMessageProps extends Omit<ITypographyProps, 'children'> {
	value?: string;
}
export const FormErrorMessage = memo<IFormErrorMessageProps>(({ value, ...props }) => {
	return (
		<Typography.Body size='small' variant='error' {...props} data-testid='FormErrorMessage'>
			{value}
		</Typography.Body>
	);
});
