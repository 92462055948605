import { Col, Flex, Row, useTheme } from '@jcm/design-system';
import { nanoid } from 'nanoid';
import { memo, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { GetParticipantesReturnType } from 'api';
import { useIsMobile } from 'hooks';
import { useDadosParticipantesFiltradosInfiniteQuery } from 'queries/admin';

import { Card, CardSkeleton } from './Card';
import { SemItens } from './SemItens';

export interface IResultadosProps {
	id?: string;
	className?: string;

	filtro: string;
}

export const Resultados = memo<IResultadosProps>(({ filtro, ...props }) => {
	const { ref, inView } = useInView();
	const { shapes } = useTheme();
	const isMobile = useIsMobile();

	const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
		useDadosParticipantesFiltradosInfiniteQuery({ filtro: filtro, tamanho: 20 });

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, fetchNextPage, hasNextPage, isFetching]);

	const handleParticipanteClicado = (participante: GetParticipantesReturnType) => {
		const currentUrl = window.location.href.split('/admin')[0];

		window.open(`${currentUrl}/participante?idParticipacao=${participante.participacaoId}`, '_blank');
	};

	const gerarSkeletonCards = () => {
		return Array.from({ length: isMobile ? 2 : 4 }, () => (
			<Col xs={24} sm={12} xl={6} key={nanoid()}>
				<CardSkeleton />
			</Col>
		));
	};

	return (
		<div {...props}>
			{!data?.pages.flat().length && !isFetching && (
				<Flex align='center' justify='center' flex={1}>
					<SemItens />
				</Flex>
			)}

			<Row gutter={[8, 8]}>
				{data?.pages.map((page) =>
					page.map((item) => {
						return (
							<Col xs={24} sm={12} xl={6} key={item.participacaoId}>
								<Card data={item} onClick={handleParticipanteClicado} />
							</Col>
						);
					}),
				)}

				{(isFetching || isFetchingNextPage) && gerarSkeletonCards()}

				<Col span={24} style={{ paddingBottom: '2rem' }}>
					<div ref={ref} style={{ height: shapes.sizes.extraSmall, width: shapes.sizes.extraSmall }} />
				</Col>
			</Row>
		</div>
	);
});
