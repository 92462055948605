import { Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { CustomTitleTable, ICustomTitleTableProps } from 'components';
import { useThemeContext } from 'context';
import { ArrayItem, DateFormatter, MesesEnum, NumberFormatter, Range } from 'lib';

import styles from './index.module.scss';

const colunasMeses = Object.values(MesesEnum)
	.filter((v) => !isNaN(Number(v)))
	.map<ArrayItem<TableType['columns']>>((mes) => {
		const numeroMes = Number(mes) as Range<1, 13>;
		const nomeMes = DateFormatter.formatMonth(numeroMes, 'MMM');

		return {
			title: nomeMes,
			key: numeroMes,
			dataIndex: numeroMes,
			render: (value: number) => NumberFormatter.format(value, 'BRL'),
		};
	});

export const fichaFinanceiraColumns: TableType['columns'] = [
	{ title: 'Proventos e descontos', key: 'nome', dataIndex: 'nome' },
	...colunasMeses,
	{ title: 'Total', key: 'total', dataIndex: 'total', render: (value: number) => NumberFormatter.format(value, 'BRL') },
];

// Nome, abono, total + 1 coluna pra cada mês
export type FichaFinanceiraDataType = { nome: string; total: number } & Record<Range<1, 13>, number>;
type TableType = ICustomTitleTableProps<FichaFinanceiraDataType>;

type Props = { title?: React.ReactNode; dataSource?: TableType['dataSource'] };
export const TabelaFichaFinanceira = memo<Props>(({ title, dataSource }) => {
	const { currentTheme } = useThemeContext();
	const { colors } = useTheme();

	const tableRowClassName: TableType['rowClassName'] = (record) => {
		const classNames: string[] = [styles.trNormal];

		if (record.nome === 'Líquido') classNames.push(styles.liquido);
		if (record.nome.includes('Total')) classNames.push(styles.trTotal);

		return classNames.join(' ');
	};

	return (
		<CustomTitleTable<FichaFinanceiraDataType>
			tableVariant='tertiary'
			containerClassName={currentTheme === 'light' ? styles.lightTableContainer : styles.darkTableContainer}
			headerStyle={{ backgroundColor: colors.tertiary, color: colors.onTertiary }}
			columns={fichaFinanceiraColumns}
			dataSource={dataSource || []}
			rowClassName={tableRowClassName}
			rowKey={(record) => record.nome}
			title={
				<Typography.Label variant='default' size='large'>
					{title}
				</Typography.Label>
			}
		/>
	);
});
