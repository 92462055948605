import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const data = [
	{
		mes: 'Jan',
		perfil1: 2700,
		perfil2: 2400,
		perfil3: 2100,
	},
	{
		mes: 'Fev',
		perfil1: 3500,
		perfil2: 1600,
		perfil3: 1300,
	},
	{
		mes: 'Mar',
		perfil1: 800,
		perfil2: 2100,
		perfil3: 1100,
	},
	{
		mes: 'Abr',
		perfil1: 400,
		perfil2: 1300,
		perfil3: 1600,
	},
	{
		mes: 'Mai',
		perfil1: 1400,
		perfil2: 4200,
		perfil3: 1900,
	},
	{
		mes: 'Jun',
		perfil1: 3900,
		perfil2: 2700,
		perfil3: 1700,
	},
	{
		mes: 'Jul',
		perfil1: 1600,
		perfil2: 1800,
		perfil3: 2600,
	},
	{
		mes: 'Ago',
		perfil1: 4000,
		perfil2: 3900,
		perfil3: 3200,
	},
	{
		mes: 'Set',
		perfil1: 1400,
		perfil2: 1800,
		perfil3: 2300,
	},
	{
		mes: 'Out',
		perfil1: 4100,
		perfil2: 1300,
		perfil3: 700,
	},
	{
		mes: 'Nov',
		perfil1: 2600,
		perfil2: 1800,
		perfil3: 1600,
	},
	{
		mes: 'Dez',
		perfil1: 1800,
		perfil2: 1700,
		perfil3: 3300,
	},
];

export const RentabilidadePerfil = () => {
	console.log(data);

	return (
		<ResponsiveContainer width='100%' height={412}>
			<BarChart
				width={500}
				height={300}
				data={data}
				barGap={4}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
				barSize={8}
			>
				<XAxis dataKey='mes' scale='point' padding={{ left: 40, right: 40 }} />
				<YAxis />
				<Tooltip />
				<Legend iconType='circle' />
				<CartesianGrid strokeDasharray='3 3' />
				<Bar dataKey='perfil1' fill='#BA1a1a' background={{ fill: '#eee' }} />
				<Bar dataKey='perfil2' fill='#90BE6D' background={{ fill: '#eee' }} />
				<Bar dataKey='perfil3' fill='#2D9CDB' background={{ fill: '#eee' }} />
			</BarChart>
		</ResponsiveContainer>
	);
};
