import { Card, Col, Row, Skeleton, Space } from '@jcm/design-system';
import { memo } from 'react';

export type LoadingSkeletonProps = { id?: string; style?: React.CSSProperties };
export const LoadingSkeleton = memo<LoadingSkeletonProps>(({ id, style }) => {
	return (
		<Card level={0} style={{ padding: '1rem', maxWidth: '450px', ...style }} id={id}>
			<Col>
				<Row>
					<Space size='middle'>
						<Skeleton.Avatar active />
						<Skeleton.Input active />
					</Space>
				</Row>
				<br />
				<Row>
					<Skeleton.Input active style={{ height: 100, width: 250 }} />
				</Row>
				<br />
				<Row>
					<Skeleton.Input active style={{ height: 100, width: 300 }} />
				</Row>
				<br />
				<Row>
					<Skeleton.Input active />
				</Row>
			</Col>
		</Card>
	);
});
