import { Container, Flex, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

type DadosCadastraisSectionType = {
	id?: string;
	title?: React.ReactNode;
	addon?: React.ReactNode;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
};
export const DadosCadastraisSection = memo<DadosCadastraisSectionType>(
	({ id, children, title, addon, style, className }) => {
		const { shapes, colors } = useTheme();

		return (
			<Container
				id={id}
				variant='default'
				level={0}
				className={className}
				style={{
					backgroundColor: colors.containerLowest,
					border: `1px solid ${colors.outlineVariant}`,
					borderRadius: shapes.sizes.large,
					...style,
				}}
			>
				<Flex gap={shapes.sizes.large} vertical style={{ height: '100%' }}>
					<Flex justify='space-between' align='center'>
						{!!title && (
							<Typography.Title size='large' style={{ fontWeight: 500, color: colors.onSurfaceVariant }}>
								{title}
							</Typography.Title>
						)}

						{addon}
					</Flex>

					{children}
				</Flex>
			</Container>
		);
	},
);
