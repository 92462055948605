import { useParticipanteIdentityContext } from 'context';
import { TipoParticipanteEnum } from 'lib';

type TipoParticipante = 'ativo' | 'assistido' | 'autopatrocinado' | 'bpd' | 'desligado';

/**
 * Depende do `tipoParticipante` do hook `useIdentityContext`!
 */
export const useTipoParticipante = (): TipoParticipante | null => {
	const { tipoParticipante } = useParticipanteIdentityContext();

	if (!tipoParticipante) return null;

	switch (tipoParticipante) {
		case TipoParticipanteEnum.AtivoComEmprestimo:
		case TipoParticipanteEnum.AtivoSemEmprestimo:
			return 'ativo';
		case TipoParticipanteEnum.AutopatrocinadoComEmprestimo:
		case TipoParticipanteEnum.AutopatrocinadoSemEmprestimo:
			return 'autopatrocinado';
		case TipoParticipanteEnum.BPDComEmprestimo:
		case TipoParticipanteEnum.BPDSemEmprestimo:
			return 'bpd';
		case TipoParticipanteEnum.PensionistaProgressivo:
		case TipoParticipanteEnum.PensionistaRegressivo:
		case TipoParticipanteEnum.RendaFinanceiraProgressivaComEmprestimo:
		case TipoParticipanteEnum.RendaFinanceiraProgressivaSemEmprestimo:
		case TipoParticipanteEnum.RendaFinanceiraRegressivaComEmprestimo:
		case TipoParticipanteEnum.RendaFinanceiraRegressivaSemEmprestimo:
		case TipoParticipanteEnum.VitalicioComEmprestimo:
		case TipoParticipanteEnum.VitalicioSemEmprestimo:
			return 'assistido';
		case TipoParticipanteEnum.DesligadoComEmprestimo:
		case TipoParticipanteEnum.DesligadoSemEmprestimo:
			return 'desligado';
	}
};
