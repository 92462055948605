export interface ICalculadoraBeneficioFiscalProps {
	salarioParticipacao: number;
	mesAtual: number;
	fatorBeneficio: number;
}

export class CalculadoraBeneficioFiscal {
	private salarioParticipacao: number;
	private mesAtual: number;
	private fatorBeneficio: number;

	constructor({ salarioParticipacao, mesAtual, fatorBeneficio }: ICalculadoraBeneficioFiscalProps) {
		this.salarioParticipacao = salarioParticipacao;
		this.mesAtual = mesAtual;
		this.fatorBeneficio = fatorBeneficio;
	}

	public calcularSaldo = (contribuicaoMensal: number) => {
		const salarios = this.salarioParticipacao * this.mesAtual * this.fatorBeneficio;
		const contribuicoes = contribuicaoMensal * this.mesAtual;

		return salarios - contribuicoes;
	};
}
