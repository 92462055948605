import { Checkbox, Col, Flex, Row, Typography } from '@jcm/design-system';
import { useParams } from 'react-router-dom';

import { useParticipacoesDadosPessoaisQuery } from 'queries/participante';
import { DadosCadastraisSection } from '../../../cards';
import { useSkeletonHelper } from 'hooks';

export const ResidenteExterior = () => {
	const params = useParams<{ participacaoId: string }>();
	const { data, isPending } = useParticipacoesDadosPessoaisQuery(params.participacaoId as string);
	const { getValueOrSkeleton } = useSkeletonHelper({ isPending });

	return (
		<DadosCadastraisSection title='Residente Exterior'>
			<Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
				<Col xs={24} md={8}>
					<Flex align='center' gap={'.5rem'}>
						<Checkbox checked={!!data?.isResidenteExterior}></Checkbox>
						<Typography.Body>{getValueOrSkeleton('Residente Exterior')}</Typography.Body>
					</Flex>
				</Col>
				<Col xs={24} md={8}>
					<Flex align='center' gap={'.5rem'}>
						<Checkbox checked={!!data?.isUsPerson}></Checkbox>
						<Typography.Body>{getValueOrSkeleton('US person')}</Typography.Body>
					</Flex>
				</Col>
			</Row>
		</DadosCadastraisSection>
	);
};
