import { App as AntApp } from 'antd';
import { BrowserRouter } from 'react-router-dom';

import { ThemeContextProvider, VisibilityContextProvider } from 'context';
import { AppRoutes } from 'routes';

import 'assets/scss/style.scss';

const App = () => {
	return (
		<AntApp>
			<BrowserRouter>
				<ThemeContextProvider>
					<VisibilityContextProvider>
						<AppRoutes />
					</VisibilityContextProvider>
				</ThemeContextProvider>
			</BrowserRouter>
		</AntApp>
	);
};

export default App;
