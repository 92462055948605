import { DesktopLayout, Flex, NavigationBar, Typography, useTheme } from '@jcm/design-system';
import { memo, useMemo } from 'react';

import {
	NotificationsIconButton,
	SearchIconButton,
	SeletorParticipacao,
	SiderMenu,
	ToggleThemeIconButton,
	ToggleVisibilityButton,
} from 'components';
import { useParticipanteIdentityContext } from 'context';
import { useNavigateWithSearchParams, useSkeletonHelper } from 'hooks';
import { DateFormatter } from 'lib';
import { useNotificacoesQuery, useParticipacoesPlanosQuery } from 'queries/participante';

import { ILayoutVariantProps } from '.';

interface IDesktopProps extends ILayoutVariantProps {}
export const Desktop = memo<IDesktopProps>(
	({ id, drawerBarItems, participacaoAtiva, children, onParticipacaoChange }) => {
		const { shapes, colors } = useTheme();
		const navigateWithSearchParams = useNavigateWithSearchParams();

		const { idParticipacao } = useParticipanteIdentityContext();
		const { data, isPending } = useParticipacoesPlanosQuery(idParticipacao);
		const { getValueOrSkeleton } = useSkeletonHelper({ isPending });

		const dataReferencia = useMemo(() => {
			if (!participacaoAtiva?.dataReferencia) return '';

			return DateFormatter.format(new Date(participacaoAtiva.dataReferencia), 'DD/MM/YYYY');
		}, [participacaoAtiva?.dataReferencia]);

		const headerContent = getValueOrSkeleton(
			data && (
				<Flex vertical gap={shapes.sizes.extraSmall}>
					<SeletorParticipacao
						idParticipacaoAtiva={participacaoAtiva?.participacaoId}
						onParticipacaoChange={onParticipacaoChange}
					/>
				</Flex>
			),
		);

		const headerIcons = (
			<>
				<Flex vertical gap={4}>
					<Typography.Body size='small' style={{ color: colors.primary }}>
						Útimo processamento
					</Typography.Body>

					<Typography.Body>{dataReferencia}</Typography.Body>
				</Flex>
				<ToggleVisibilityButton loading={isPending} />
				<SearchIconButton />
				<NotificationsIconButton useQueryFn={useNotificacoesQuery} />
				<ToggleThemeIconButton />
			</>
		);

		return (
			<DesktopLayout
				id={id}
				sider={
					<SiderMenu
						items={drawerBarItems}
						onSelect={(info) => navigateWithSearchParams(info.key)}
						nome={participacaoAtiva?.nome.split(' ')[0]}
						status={participacaoAtiva?.status}
					/>
				}
				header={<NavigationBar container='surface' content={headerContent} icons={headerIcons} />}
			>
				{children}
			</DesktopLayout>
		);
	},
);
