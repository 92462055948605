import { Col, Flex, Row, Typography, useTheme } from '@jcm/design-system';
import { useSkeletonHelper } from 'hooks';
import { DateFormatter, NumberFormatter } from 'lib';
import { DadosCadastraisSection } from 'pages/admin/dadosCadastrais/cards';
import React from 'react';
import { DadosGeraisSectionProps } from './DadosGeraisSectionType';

export const SectionContratoELocalizacao: React.FC<DadosGeraisSectionProps> = ({ data, isPending }) => {
	const { colors } = useTheme();
	const { getValueOrSkeleton } = useSkeletonHelper({ isPending });

	// lista iteravel com informações do contrato de trabalho
	const contratoTrabalhoItems = [
		{ key: 'empregador', title: 'Empregador', value: data?.empregador },
		{ key: 'salario-atual', title: 'Salário atual', value: NumberFormatter.format(data?.salario, 'BRL') },
		{
			key: 'data-de-admissao',
			title: 'Data de admissão',
			value: DateFormatter.toDayjs(data?.dataAdmissao).format('DD/MM/YYYY'),
		},
		{ key: 'matricula', title: 'Matrícula', value: data?.matricula },
		{ key: 'situacao', title: 'Situação', value: data?.situacaoEmprego },
	];

	// lista iteravel com informações da localização
	const localizacaoItems = [
		{ key: 'lotacao', title: 'Lotação', value: data?.lotacao },
		{ key: 'municipio-lotacao', title: 'Município da lotação', value: data?.municipio },
	];

	return (
		<DadosCadastraisSection title='Dados do contrato de trabalho' id='dados-contrato-trabalho'>
			<Row gutter={[16, 16]} justify={'start'}>
				{contratoTrabalhoItems.map((item) => (
					<Col lg={8} key={item.key}>
						<Flex vertical gap={8}>
							<Typography.Title variant='tertiary'>{item.title}</Typography.Title>
							{getValueOrSkeleton(<Typography.Body>{item.value}</Typography.Body>)}
						</Flex>
					</Col>
				))}
			</Row>
			<Typography.Title size='large' style={{ fontWeight: 500, color: colors.onSurfaceVariant }}>
				Localização
			</Typography.Title>
			<Row gutter={[16, 16]}>
				{localizacaoItems.map((item) => (
					<Col lg={6} key={item.key}>
						<Flex vertical gap={8}>
							<Typography.Title variant='tertiary'>{item.title}</Typography.Title>
							{getValueOrSkeleton(<Typography.Body>{item.value}</Typography.Body>)}
						</Flex>
					</Col>
				))}
			</Row>
		</DadosCadastraisSection>
	);
};
