import { Checkbox, Col, Flex, Row, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { ParticipacoesDadosContatoTelefone } from 'api';
import { useParticipacoesContatoQuery } from 'queries/participante';

import { DadosCadastraisSection } from '../../../cards';

const Telefone = memo<{ dadosTelefone: ParticipacoesDadosContatoTelefone; index: number }>(
	({ dadosTelefone, index }) => {
		const { colors } = useTheme();

		return (
			<>
				<Typography.Title size='small' strong style={{ color: colors.tertiary }}>
					Telefone {index + 1}
				</Typography.Title>

				<Row gutter={[16, 16]} style={{ justifyContent: 'space-between' }}>
					<Col xs={24} md={8} style={{ display: 'flex', flexDirection: 'column', gap: 4, minWidth: 'fit-content' }}>
						<Typography.Body>
							{dadosTelefone.ddi} {dadosTelefone.ddd} {dadosTelefone.numero}
						</Typography.Body>
						<Flex align='center' gap={'.5rem'}>
							<Checkbox checked={!!dadosTelefone?.whatsappSn}></Checkbox>
							<Typography.Body>Whatsapp</Typography.Body>
						</Flex>
					</Col>
				</Row>
			</>
		);
	},
);
export const Telefones: React.FC = () => {
	// Extrair id do participante da URL
	const params = useParams<{ participacaoId: string }>();
	const { data } = useParticipacoesContatoQuery(params.participacaoId as string);

	return (
		<DadosCadastraisSection title='Telefones'>
			<Row gutter={[16, 16]}>
				{data?.telefones.map((dadosTelefone, index) => (
					<Col key={dadosTelefone.id} xs={24} md={12} style={{ gap: 250 }}>
						<Telefone dadosTelefone={dadosTelefone} index={index} />
					</Col>
				))}
			</Row>
		</DadosCadastraisSection>
	);
};
