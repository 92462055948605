import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const data = [
	{
		titulo: 'Básica',
		participante: 6000,
		patrocinadora: 2400,
	},
	{
		titulo: 'Adicional',
		participante: 8000,
		patrocinadora: 4000,
	},
	{
		titulo: 'Voluntária',
		participante: 4000,
		patrocinadora: 0,
	},
];

export const PatrimonioConta = () => {
	console.log(data);

	return (
		<ResponsiveContainer width='100%' height={412}>
			<BarChart
				layout='vertical'
				width={500}
				height={300}
				data={data}
				margin={{
					top: 20,
					right: 30,
					left: 20,
					bottom: 20,
				}}
				barSize={12}
			>
				<YAxis padding={{ top: 40, bottom: 40 }} type='category' dataKey='titulo' scale='point' />
				<XAxis type='number' />
				<Tooltip />
				<Legend iconType='circle' />
				<CartesianGrid strokeDasharray='3 3' />
				<Bar dataKey='participante' fill='#186584' background={{ fill: '#eee' }} />
				<Bar dataKey='patrocinadora' fill='#964169' background={{ fill: '#eee' }} />
			</BarChart>
		</ResponsiveContainer>
	);
};
