import { IRadioGroupProps, ISelectProps, RadioGroup, Select } from '@jcm/design-system';
import { memo } from 'react';

import { useIsMobile } from 'hooks';

type OptionType = { label: React.ReactNode; value: string | number };
export interface ISeletorResponsivoProps {
	id?: string;
	style?: React.CSSProperties;

	options: OptionType[];
	value?: IRadioGroupProps['value'] | ISelectProps['value'];
	defaultValue?: IRadioGroupProps['defaultValue'] | ISelectProps['defaultValue'];
	onChange?: (value: IRadioGroupProps['value']) => void;
}

export const SeletorResponsivo = memo<ISeletorResponsivoProps>(({ onChange, ...props }) => {
	const isMobile = useIsMobile();

	return (
		<>
			{isMobile ? (
				<Select {...props} variant='filled' onChange={(e) => onChange?.(e)} />
			) : (
				<RadioGroup {...props} onChange={(e) => onChange?.(e.target.value)} optionType='button' />
			)}
		</>
	);
});
