import { useTheme } from '@jcm/design-system';
import { nanoid } from 'nanoid';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { MesesAbreviadosEnum } from 'lib';

import { getCoresPorTipoDeDado } from '..';

type ComparativoRentabilidadeChartData = Record<TiposDeDado, number> & { mes: string };
const getData = (): ComparativoRentabilidadeChartData[] => {
	const meses = Object.keys(MesesAbreviadosEnum).filter((item) => Number.isNaN(Number(item)));
	const getValor = () => {
		const valor = Math.floor(Math.random() * 4000);
		return valor;
	};
	return meses.map((mes) => {
		return {
			mes: mes,
			CDI: getValor(),
			IPCA: getValor(),
			Ibovespa: getValor(),
			Perfil1: getValor(),
			Perfil2: getValor(),
			Perfil3: getValor(),
			Perfil4: getValor(),
			Perfil5: getValor(),
			Perfil6: getValor(),
			Poupança: getValor(),
		};
	});
};
const data = getData();

export type TiposDeDado =
	| 'Perfil1'
	| 'Poupança'
	| 'CDI'
	| 'IPCA'
	| 'Ibovespa'
	| 'Perfil2'
	| 'Perfil3'
	| 'Perfil4'
	| 'Perfil5'
	| 'Perfil6';
export interface IComparativoDeRentabilidadeProps {
	tiposDeDados: TiposDeDado[];
}

export const ComparativoRentabilidade = (props: IComparativoDeRentabilidadeProps) => {
	const { colors } = useTheme();
	const coresPorTipoDeDado = getCoresPorTipoDeDado(colors);

	return (
		<ResponsiveContainer width='100%' height={300}>
			<LineChart
				width={500}
				height={300}
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray='3 3' />
				<XAxis dataKey='mes' />
				<YAxis yAxisId='left' />

				<Tooltip />
				<Legend />
				{props.tiposDeDados.map((tipo) => (
					<Line
						key={nanoid()}
						strokeWidth='3'
						yAxisId='left'
						type='monotone'
						dataKey={tipo}
						stroke={coresPorTipoDeDado[tipo]}
						activeDot={{ r: 8 }}
					/>
				))}
			</LineChart>
		</ResponsiveContainer>
	);
};
