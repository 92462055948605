import { Card, Flex, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

export interface IDashboardCardProps {
	id?: string;
	className?: string;
	style?: React.CSSProperties;

	title?: string;
	compData?: string;
	subTitle?: string;

	addon?: React.ReactNode;
	children: React.ReactNode;
}
export const DashboardCard = memo<IDashboardCardProps>(
	({ title, compData, subTitle, addon, children, style, ...props }) => {
		const { colors, shapes } = useTheme();

		return (
			<Card
				style={{
					height: '100%',
					width: '100%',
					backgroundColor: colors.containerLowest,
					padding: shapes.sizes.large,
					borderRadius: shapes.sizes.medium,
					...style,
				}}
				{...props}
			>
				<Flex vertical gap={shapes.sizes.large}>
					<Flex justify='space-between'>
						<Flex
							vertical
							gap={'1rem'}
							style={{
								width: '100%',
								paddingBottom: shapes.sizes.large,
								borderBottom: '1px solid ' + colors.outlineVariant,
							}}
						>
							{!!title && (
								<Typography.Title
									size='large'
									style={{
										fontWeight: 500,
										fontSize: '1.25rem',
									}}
								>
									{title}
								</Typography.Title>
							)}
							{!!compData && (
								<Typography.Title
									size='large'
									style={{
										fontWeight: 700,
										fontSize: '2rem',
									}}
								>
									{compData}
								</Typography.Title>
							)}
							{!!subTitle && (
								<Typography.Body
									size='large'
									style={{
										fontWeight: 500,
										fontSize: '1rem',
									}}
								>
									{subTitle}
								</Typography.Body>
							)}
						</Flex>
						{addon}
					</Flex>

					{children}
				</Flex>
			</Card>
		);
	},
);
