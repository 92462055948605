import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from 'nanoid';

import { ExtratoType } from 'components/tabelas';
import { DateFormatter, NumberFormatter } from 'lib';

import { Template } from './Template';

const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
	},

	col: {
		display: 'flex',
		flexDirection: 'column',
	},

	table: {
		margin: 8,
		fontSize: 10,
	},
});

type ExtratoSaldoPDFProps = {
	inicio: string;
	fim: string;
	data?: ExtratoType[];
};

export const ExtratoSaldoPDF: React.FC<ExtratoSaldoPDFProps> = ({ inicio, fim, data }) => {
	const dataInicial = DateFormatter.toDate(inicio);
	const dataFinal = DateFormatter.toDate(fim);

	const subTitle = `${DateFormatter.format(dataInicial)} a ${DateFormatter.format(dataFinal)} (SUBTÍTULO)`;

	const mappedData = data?.map((item) => {
		return [
			item.colunaTipo,

			item.colunaPerfil1,
			item.colunaConta,

			NumberFormatter.format(item.colunaValor1 as number, 'BRL'),
			NumberFormatter.format(item.colunaCotas1 as number, 'default', { minimumFractionDigits: 4 }),

			// NumberFormatter.format(item.colunaValor2 as number, 'BRL'),
			// NumberFormatter.format(item.colunaCotas2 as number, 'default', { minimumFractionDigits: 4 }),

			// NumberFormatter.format(item.colunaValor3 as number, 'BRL'),
			// NumberFormatter.format(item.colunaCotas3 as number, 'default', { minimumFractionDigits: 4 }),
		];
	});

	return (
		<Template title='Extrato de Saldo (TÍTULO)' subTitle={subTitle}>
			<View style={[styles.col, { gap: 4 }]}>
				<Text style={{ color: 'red' }}>ESSE DOCUMENTO É UMA DEMONSTRAÇÃO!</Text>
				<Text>O cabeçalho do documento (acima) é fixo, mas podemos editar título e subtítulo.</Text>

				<Text style={{ marginTop: 4 }}>Esse é o conteúdo do documento, podemos exibir dados aqui dentro:</Text>

				<View style={[styles.col, styles.table]}>
					{mappedData?.map((row) => {
						return (
							<View key={nanoid()} style={[styles.row, { gap: 4 }]}>
								{row.map((col) => {
									return (
										<View key={nanoid()} style={[styles.col, { flex: 1 }]}>
											<Text>{col}</Text>
										</View>
									);
								})}
							</View>
						);
					})}
				</View>
			</View>
		</Template>
	);
};
