import { Flex } from '@jcm/design-system';
import { useParticipacoesDadosPlanosQuery } from 'queries/participante';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { SectionContratoELocalizacao } from './SectionContratoELocalizacao';
import { SectionDadosGeraisPlano } from './SectionDadosGeraisPlano';

export const DadosGerais = memo(() => {
	const params = useParams<{ participacaoId: string }>();
	const { data, isPending } = useParticipacoesDadosPlanosQuery(params.participacaoId as string, {
		isProduction: false,
	});

	return (
		<Flex className='cardDemonstracao' gap={16} vertical>
			<SectionDadosGeraisPlano data={data} isPending={isPending} />
			<SectionContratoELocalizacao data={data} isPending={isPending} />
		</Flex>
	);
});
