import { Dispatch, useEffect, useReducer } from 'react';

export type ObjectReducerActionType<TData = unknown> =
	| { type: 'update'; value: Partial<TData> }
	| { type: 'validate' | 'save' | 'reset' };
export type ObjectReducerDispatch<T = unknown> = Dispatch<ObjectReducerActionType<T>>;

export type ObjectReducerOptions<TData = unknown> = {
	onValidate?: (data?: TData) => void;
	onSave?: (data?: TData) => void;
	onReset?: () => void;
};
export type ObjectReducerReturnType<TData = unknown> = [Partial<TData>, ObjectReducerDispatch<TData>];

/**
 * Hook feito pra abstrair a lógica de manuseio de dados de um formulário,
 * com métodos para atualizar os dados, resetar aos valores iniciais, validar o formulário e salvar.
 *
 * @param data Objeto que contém os dados do formulário. **Não suporta objetos aninhados por enquanto!**
 * @param options Opções e callbacks, como `onSave` e `onValidate`
 * @returns Mesmo retorno de `useReducer`
 */
export const useObjectFormReducer = <TData>(initialData?: TData, options?: ObjectReducerOptions<TData>) => {
	const reducer = (state: TData | undefined, action: ObjectReducerActionType<TData>) => {
		switch (action.type) {
			case 'update':
				return { ...state, ...action.value } as TData;

			case 'validate':
				options?.onValidate?.(state);
				return state;

			case 'reset':
				options?.onReset?.();
				return initialData;

			case 'save':
				options?.onSave?.(state);
				return state;
		}
	};

	const [state, dispatch] = useReducer(reducer, initialData);

	// Qualquer alteração nos dados iniciais gera um evento "reset"
	// Dessa forma, um `refetch` na query de origem dos dados vai gerar um update nan interface
	useEffect(() => {
		if (!initialData) return;
		dispatch({ type: 'reset' });
	}, [initialData]);

	return [state, dispatch] as ObjectReducerReturnType<TData>;
};
