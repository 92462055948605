import '@fontsource/cabin';

import { Flex, useTheme } from '@jcm/design-system';
import { memo } from 'react';

export const SeloISO = memo(() => {
	const { logos, shapes, colors } = useTheme();

	return (
		<Flex align='center' justify='center' gap={shapes.sizes.small}>
			<img src={logos.certIsoLogo} style={{ width: 'auto', height: '36px' }} alt='Selo ISO27001' />

			<Flex
				vertical
				style={{
					fontFamily: 'Cabin',
					userSelect: 'none',
					fontSize: '12px',
					fontWeight: 400,
					lineHeight: 'normal',
					color: colors.onSurfaceVariant,
				}}
			>
				<span>Certificado ISO 27001</span>
				<span>Segurança da informação</span>
			</Flex>
		</Flex>
	);
});
