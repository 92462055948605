import { Card, Flex, Skeleton, Typography, VariantType, useTheme } from '@jcm/design-system';
import { SkeletonInputProps } from 'antd/es/skeleton/Input';
import { memo, useCallback, useMemo } from 'react';

import { getCurrencyOrDataPlaceholder, getValueOrPercentPlaceholder } from 'components';
import { useIsMobile } from 'hooks';

type InfoSaldoCardProps = {
	variant: VariantType;
	icon: React.ReactNode;
	title: React.ReactNode;
	value: React.ReactNode;
	resgate?: number;

	isLoading?: boolean;

	id?: string;
	style?: React.CSSProperties;
};
export const InfoSaldoCard = memo<InfoSaldoCardProps>(
	({ variant, icon, title, value, resgate, isLoading, style, ...props }) => {
		const isMobile = useIsMobile();
		const { shapes } = useTheme();

		const defaultSkeletonProps = useMemo<SkeletonInputProps>(() => {
			return { size: 'small', style: { height: shapes.sizes.extraLarge } };
		}, [shapes.sizes.extraLarge]);

		const getValueOrSkeleton = useCallback(
			(value: React.ReactNode, skeletonProps: SkeletonInputProps = defaultSkeletonProps) => {
				return isLoading ? <Skeleton.Input active {...skeletonProps} /> : value;
			},
			[isLoading, defaultSkeletonProps],
		);

		const getValueOrNull = useCallback((value: React.ReactNode) => (isLoading ? null : value), [isLoading]);

		const iconNode =
			!!icon &&
			getValueOrNull(
				<Typography.Title size='large' variant={variant}>
					{icon}
				</Typography.Title>,
			);

		const defaultStyle: React.CSSProperties = {
			userSelect: 'none',
			padding: `${shapes.sizes.medium} ${shapes.sizes.large}`,
			borderRadius: isMobile ? shapes.sizes.medium : shapes.sizes.large,
		};

		return (
			<Card variant={variant} style={{ ...defaultStyle, ...style }} {...props}>
				<Flex
					gap={shapes.sizes.extraSmall}
					align={isMobile ? 'center' : 'start'}
					justify='space-between'
					flex={1}
					vertical={!isMobile}
				>
					<Flex gap={shapes.sizes.medium} align='center'>
						{isMobile && iconNode}

						<Flex gap={shapes.sizes.extraSmall} vertical>
							<Flex gap={shapes.sizes.extraSmall} align='center'>
								{!isMobile && iconNode}

								<Typography.Body size={isMobile ? 'medium' : 'large'}>{getValueOrSkeleton(title)}</Typography.Body>
							</Flex>

							<Typography.Title size={isMobile ? 'small' : 'medium'} variant={variant} style={{ fontWeight: 500 }}>
								{getValueOrSkeleton(getCurrencyOrDataPlaceholder(value))}
							</Typography.Title>
						</Flex>
					</Flex>

					{!!resgate && (
						<Typography.Body
							size='small'
							style={{ alignSelf: 'start', fontSize: isMobile ? '0.6rem' : shapes.sizes.medium }}
						>
							{getValueOrNull(<>Resgate {getValueOrPercentPlaceholder(resgate)}</>)}
						</Typography.Body>
					)}
				</Flex>
			</Card>
		);
	},
);
