import { NotFound } from '@jcm/design-system';
import { memo, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { ErrorBoundary } from 'components';
import { KeycloakContextProvider, ParticipanteIdentityContextProvider, QueryClientContextProvider } from 'context';
import { LayoutAdmin, LayoutHome, LayoutParticipante } from 'layouts';
import { Admin, Home, Participante } from 'pages';

const ParticipanteElement = memo(() => {
	return (
		<ErrorBoundary>
			<KeycloakContextProvider>
				<QueryClientContextProvider>
					<ParticipanteIdentityContextProvider>
						<LayoutParticipante />
					</ParticipanteIdentityContextProvider>
				</QueryClientContextProvider>
			</KeycloakContextProvider>
		</ErrorBoundary>
	);
});

const AdminElement = memo(() => {
	return (
		<ErrorBoundary>
			<KeycloakContextProvider admin>
				<QueryClientContextProvider>
					<LayoutAdmin />
				</QueryClientContextProvider>
			</KeycloakContextProvider>
		</ErrorBoundary>
	);
});

export const AppRoutes = memo(() => {
	const location = useLocation();

	// A cada navegação, voltamos ao topo da tela
	useEffect(() => window.scrollTo(0, 0), [location]);

	return (
		<Routes>
			{/* Rotas desprotegidas */}
			<Route path='/' element={<LayoutHome />}>
				<Route index element={<Home />} />
			</Route>

			{/* Rotas protegidas */}
			{/* ROTA DA PÁGINA ADMIN */}
			<Route path='/admin/*' element={<AdminElement />}>
				<Route path='*' element={<Admin />} />
			</Route>

			{/* ROTA DA PÁGINA PARTICIPANTE */}
			<Route path='/participante/*' element={<ParticipanteElement />}>
				<Route path='*' element={<Participante />} />
			</Route>

			<Route path='*' element={<NotFound style={{ minHeight: '100vh' }} />} />
		</Routes>
	);
});
