import { Divider, Flex, SearchBar, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

export type HeaderProps = {
	id?: string;
	className?: string;
	style?: React.CSSProperties;

	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Header = memo<HeaderProps>(({ onChange, value, ...props }) => {
	const { shapes } = useTheme();

	return (
		<Flex vertical gap={shapes.sizes.small} {...props}>
			<Flex>
				<Typography.Title size='large' variant='primary' style={{ fontWeight: 600 }}>
					Filtro
				</Typography.Title>
			</Flex>

			<Divider style={{ margin: 0 }} />

			<SearchBar
				variant='filled'
				placeholder='Pesquisar'
				style={{ height: '36px' }}
				value={value}
				onChange={onChange}
				allowClear
			/>
		</Flex>
	);
});
