import { useInfiniteQuery } from '@tanstack/react-query';

import { IKeycloakContext, useKeycloakContext } from 'context';
import { useApi, useGlobalConfigFile } from 'hooks';

import { AdminApi, GetParticipantesArgs, GetParticipantesReturnType, IAdminApi } from 'api';
import { AdminApi as MockAdminApi } from 'api/__mocks__';
import { QueryOptions, UseInfiniteQueryWithCustomArgsFn } from 'queries';

// Hook
export const useAdminApi = (kcContext: IKeycloakContext, options?: QueryOptions): IAdminApi => {
	const { apiConfig } = useGlobalConfigFile();
	const axiosApi = useApi({ baseURL: apiConfig.url, headers: { Authorization: `Bearer ${kcContext.accessToken}` } });

	const apiProps = { api: axiosApi, clienteId: apiConfig.clienteId };
	const mockApiProps = { delay: 1000 };

	if (options?.isProduction !== undefined) {
		return options.isProduction ? new AdminApi(apiProps) : new MockAdminApi(mockApiProps);
	}

	return process.env.NODE_ENV === 'production' ? new AdminApi(apiProps) : new MockAdminApi(mockApiProps);
};

// Filtro
export const useDadosParticipantesFiltradosInfiniteQuery: UseInfiniteQueryWithCustomArgsFn<
	GetParticipantesReturnType[],
	Omit<GetParticipantesArgs, 'pagina'>
> = ({ filtro, tamanho }, options) => {
	const kcContext = useKeycloakContext();
	const adminApi = useAdminApi(kcContext, options);

	return useInfiniteQuery({
		queryKey: ['participanteFiltro', filtro, ...(options?.keys ?? [])],
		queryFn: ({ pageParam = 1 }) => adminApi.getParticipantes({ filtro, pagina: Number(pageParam), tamanho }),
		getNextPageParam: (page, allPages) => {
			if (page.length < tamanho) return undefined;

			return allPages.length > 0 ? allPages.length + 1 : 1;
		},
		initialPageParam: 1,
		enabled: filtro.length > 0,
	});
};
