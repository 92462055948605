import { Button, Col, Flex, Row, Typography } from '@jcm/design-system';
import { useSkeletonHelper } from 'hooks';
import { DateFormatter } from 'lib';
import { DadosCadastraisSection } from 'pages/admin/dadosCadastrais/cards';
import { DadosGeraisSectionProps } from './DadosGeraisSectionType';

export const SectionDadosGeraisPlano: React.FC<DadosGeraisSectionProps> = ({ data, isPending }) => {
	const { getValueOrSkeleton } = useSkeletonHelper({ isPending });

	// lista iteravel com informações dos Dados Gerais do Plano
	const dadosGeraisPlanoItems = [
		{ key: 'plano-nome', title: 'Plano', value: data?.plano },
		{ key: 'regime-tributacao', title: 'Regime de tributação', value: data?.regimeTributacao },
		{
			key: 'data-adesao',
			title: 'Data de adesão',
			value: DateFormatter.toDayjs(data?.dataAdesaoPlano).format('DD/MM/YYYY'),
		},
		{ key: 'perfil-investimento', title: 'Perfil de investimento (questionário)', value: data?.perfilInvestimento },
		{ key: 'vinculo-participante', title: 'Vínculo do participante', value: data?.tipoVinculoParticipacao },
		{
			key: 'opcao-perfil-investimento',
			title: 'Opção de perfil de investimento',
			value: data?.opcaoPerfilInvestimento,
		},
		{ key: 'situacao-participante', title: 'Situação do participante', value: data?.situacaoParticipacao },
	];

	return (
		<DadosCadastraisSection title='Dados gerais do plano' id='dados-gerais-plano'>
			<Row gutter={[16, 16]}>
				{dadosGeraisPlanoItems.map((item) => (
					<Col xs={24} md={12} key={item.key}>
						<Flex vertical gap={8}>
							<Typography.Title variant='tertiary'>{item.title}</Typography.Title>
							{getValueOrSkeleton(<Typography.Body>{item.value}</Typography.Body>)}
						</Flex>
					</Col>
				))}
				<Col>
					<Flex align='center' style={{ height: '100%' }}>
						<Button type='filled-tonal'>Histórico do plano</Button>
					</Flex>
				</Col>
			</Row>
		</DadosCadastraisSection>
	);
};
