import { GetFn } from 'api';
import { GetParticipantesArgs, GetParticipantesReturnType, IAdminApi } from 'api/AdminApi';

import { BaseApi } from './BaseApi';

export const mockGetDadosParticipantesFiltradosSuccessData: GetParticipantesReturnType[] = [
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
	{
		cpf: 12345678901,
		empregador: 'Pepsico do Brasil Ltda.',
		nome: 'Benjamim Barbosa',
		matricula: 123456789,
		participacaoId: 1234,
		plano: 'Plano I',
		tipoVinculoParticipacao: 'Normal',
		situacaoParticipacao: 'Normal',
	},
];

export class AdminApi extends BaseApi implements IAdminApi {
	public getParticipantes: GetFn<GetParticipantesReturnType[], GetParticipantesArgs> = async () => {
		return this.doRequest(mockGetDadosParticipantesFiltradosSuccessData);
	};
}
