import { Flex } from '@jcm/design-system';

import { ContasBancarias } from './contasBancarias';
import { InformacoesPessoais } from './informacoesPessoais';
import { ResidenteExterior } from './residenteExterior';
import { ErrorBoundary } from 'components';

export const DadosPessoais = () => {
	return (
		<Flex vertical style={{ gap: 20 }}>
			<ErrorBoundary>
				<InformacoesPessoais />
			</ErrorBoundary>

			<ErrorBoundary>
				<ContasBancarias />
			</ErrorBoundary>

			<ErrorBoundary>
				<ResidenteExterior />
			</ErrorBoundary>
		</Flex>
	);
};
