import { DrawerBar, Flex, IDrawerBarProps, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { SeloISO } from './SeloISO';
import { UserAvatar } from './UserAvatar';

type SiderMenuProps = {
	nome?: string;
	status?: string;
	items: IDrawerBarProps['items'];
	onSelect: IDrawerBarProps['onSelect'];
};
export const SiderMenu = memo<SiderMenuProps>(({ nome, status, items, onSelect }) => {
	const { shapes, logos } = useTheme();

	return (
		<>
			<Flex style={{ margin: `${shapes.sizes.extraLarge} ${shapes.sizes.large}` }}>
				<img
					src={logos.brandLogo}
					style={{ margin: 'auto', width: 'auto', height: '40px', maxWidth: '100%' }}
					alt='Logo JCM'
				/>
			</Flex>

			<Flex justify='start' style={{ margin: `0 0 0 ${shapes.sizes.large}`, cursor: 'pointer' }}>
				<UserAvatar nome={nome} status={status} />
			</Flex>

			<DrawerBar items={items} onSelect={onSelect} container='surface' style={{ flexGrow: 1 }} />

			<div style={{ margin: `${shapes.sizes.large} ${shapes.sizes.small}` }}>
				<SeloISO />
			</div>
		</>
	);
});
