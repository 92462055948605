import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { DateFormatter } from 'lib';

import { Logo } from './Logo';

const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
	},

	col: {
		display: 'flex',
		flexDirection: 'column',
	},

	// Header
	header: {
		marginTop: 16,
		marginBottom: 16,
		display: 'flex',

		fontSize: 8,
	},

	logo: {
		width: 200,
	},
});

type HeaderProps = { title?: React.ReactNode; subTitle?: React.ReactNode };

export const Header: React.FC<HeaderProps> = ({ title, subTitle }) => {
	const timestamp = DateFormatter.toDate();

	return (
		<View style={[styles.header, styles.row, { justifyContent: 'space-between', alignItems: 'center' }]} fixed>
			<Logo style={styles.logo} />

			<View style={[styles.row, { alignItems: 'center', gap: 16 }]}>
				<View style={[styles.col, { alignItems: 'center', gap: 4 }]}>
					<View style={[styles.col, { alignItems: 'center' }]}>
						<Text>JCM etc etc</Text>
						<Text>CNPJ: 000000000000</Text>
					</View>

					<View style={[styles.col, { alignItems: 'center' }]}>
						<Text>{title}</Text>
						<Text>{subTitle}</Text>
					</View>
				</View>

				<View style={[styles.col, { alignItems: 'center', backgroundColor: '#d3d3d3', padding: '4 8' }]}>
					<Text>Emissão: {timestamp.toLocaleString()}</Text>
					<Text render={({ pageNumber, totalPages }) => `Página ${pageNumber}/${totalPages}`} />
				</View>
			</View>
		</View>
	);
};
