import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const COLORS = ['#006D41', '#F8961E', '#BA1A1A'];

const data = [
	{
		nome: 'Quitados',
		valor: 468523,
	},
	{
		nome: 'A receber',
		valor: 836523,
	},
	{
		nome: 'Em atraso',
		valor: 226359,
	},
];

export const EmprestimosAtivos = () => {
	console.log(data);

	return (
		<ResponsiveContainer width='100%' height={412}>
			<BarChart
				width={450}
				height={300}
				data={data}
				margin={{
					top: 5,
					right: 12,
					left: 12,
					bottom: 5,
				}}
				barSize={20}
			>
				<XAxis dataKey='valor' scale='point' padding={{ left: 40, right: 40 }} />
				<YAxis />
				<Tooltip />
				<Legend
					payload={[
						{ value: 'Quitados', color: '#006D41' },
						{ value: 'A receber', color: '#F8961E' },
						{ value: 'Em atraso', color: '#BA1a1a' },
					]}
				/>

				<CartesianGrid strokeDasharray='3 3' />
				<Bar dataKey='valor' background={{ fill: '#eee' }}>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index]} />
					))}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};
