import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const COLORS = ['#6A4FA3', '#AF3000', '#F8961E', '#F9C74F', '#90BE6D', '#2D9CDB', '#F94144'];

export const ArrecadacaoPlano = () => {
	const data = [
		{
			nomePlano: 'Plano A',
			valorArrecadacao: 400,
		},
		{
			nomePlano: 'Plano B',
			valorArrecadacao: 2500,
		},
		{
			nomePlano: 'Plano C',
			valorArrecadacao: 2400,
		},
		{
			nomePlano: 'Plano D',
			valorArrecadacao: 1800,
		},
		{
			nomePlano: 'Plano E',
			valorArrecadacao: 2000,
		},
		{
			nomePlano: 'Plano F',
			valorArrecadacao: 800,
		},
		{
			nomePlano: 'Plano G',
			valorArrecadacao: 1200,
		},
	];

	return (
		<ResponsiveContainer width='100%' height={412}>
			<BarChart
				layout='vertical'
				width={500}
				height={300}
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
				barSize={12}
			>
				<YAxis padding={{ top: 40, bottom: 40 }} type='category' dataKey='nomePlano' scale='point' />
				<XAxis type='number' />
				<Tooltip />
				<CartesianGrid strokeDasharray='3 3' />
				<Bar dataKey='valorArrecadacao' background={{ fill: '#eee' }}>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index]} />
					))}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};
