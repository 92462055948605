import { NavigateOptions, To, useNavigate, useSearchParams } from 'react-router-dom';

/**
 * Retorna uma função de navegação, que mantém todos os searchParams da URL
 */
interface INavigateWithSearchParamsFunction {
	(to: To, options?: NavigateOptions): void;
	(delta: number): void;
}
export const useNavigateWithSearchParams = (): INavigateWithSearchParamsFunction => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	return ((to, options) => {
		if (typeof to === 'number') return navigate(to, options);

		const params: string[] = [];
		searchParams.forEach((value, key) => params.push(`${key}=${value}`));
		const path = `${to}?${params.join('&')}`;

		return navigate(path, options);
	}) as INavigateWithSearchParamsFunction;
};
