/**
 * Hook que retorna o env atual.
 *
 * @returns `"development"` | `"production"` | `"test"`
 */
export const useNodeEnv = () => {
	const env = process.env.NODE_ENV;

	return env;
};
