import { Col, Icons, Row } from '@jcm/design-system';
import { memo } from 'react';

import { ErrorBoundary, PaginaParticipante } from 'components';
import { Tabs } from './tabs';

export const DadosCadastrais = memo(() => {
	return (
		<PaginaParticipante
			id='pagina-dados-cadastrais'
			title='Dados cadastrais'
			icon={<Icons.Person variant='outlined' />}
		>
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<ErrorBoundary>
						<Tabs />
					</ErrorBoundary>
				</Col>
			</Row>
		</PaginaParticipante>
	);
});
