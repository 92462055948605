type FormatType = 'default' | '%' | 'BRL';

/**
 * Classe responsável por abrigar os métodos relacionados à formatação de valores numéricos
 */
export class NumberFormatter {
	/**
	 * Método responsável por tratar da formatação de qualquer dado numérico.
	 *
	 * Para porcentagens: 1 é 100%, 0.5 é 50% e assim por diante!
	 *
	 * @param number - Número a ser formatado.
	 * @param format - Formato de destino (BRL, %, etc.).
	 * @param overrides - Opções que sobrescrevem a formatação padrão.
	 * @returns `string` que representa o número formatado.
	 */
	static format = (number = 0, format: FormatType = 'default', overrides?: Intl.NumberFormatOptions) => {
		let localeStringOptions: Intl.NumberFormatOptions | undefined;

		switch (format) {
			case '%':
				localeStringOptions = { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 };
				break;

			case 'BRL':
				localeStringOptions = { style: 'currency', currency: 'BRL' };
				break;
		}

		return number.toLocaleString('pt-BR', { ...localeStringOptions, ...overrides });
	};
}
