import { Col, Row, Icons, Typography, useTheme, Flex } from '@jcm/design-system';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useParticipacoesContatoQuery } from 'queries/participante';
// TODO - Alterar para a API correta quando ela existir.

import { DadosCadastraisSection } from '../../cards';

const Documento = memo(() => {
	const { colors } = useTheme();

	return (
		<Flex className='cardDemonstracao' style={{ gap: '1rem', flexDirection: 'column' }}>
			<Typography.Title size='small' strong style={{ color: colors.tertiary }}>
				Tipo de documento
			</Typography.Title>

			<Flex
				style={{
					backgroundColor: colors.surfaceBright,
					gap: '.25rem',
					alignContent: 'center',
					alignSelf: 'stretch',
					padding: '1rem',
					borderRadius: 12,
				}}
			>
				{/* TODO - Incluir o ícone correto na biblioteca jcm. */}
				<Icons.FilePresent style={{ fontSize: 50 }} />

				<Typography.Title size='medium' strong style={{ alignContent: 'center' }}>
					nomeDoArquivo.formato
				</Typography.Title>
			</Flex>
		</Flex>
	);
});

export const Documentos: React.FC = () => {
	// Extrair id do participante da URL
	// TODO - Alterar para a API correta quando ela existir.
	const params = useParams<{ participacaoId: string }>();
	const { data } = useParticipacoesContatoQuery(params.participacaoId as string);

	return (
		<DadosCadastraisSection title='Documentos'>
			<Row gutter={[16, 16]}>
				{data?.emails.map((dadosEmail) => (
					<Col key={dadosEmail.id} xs={24} style={{ gap: 250 }}>
						<Documento />
					</Col>
				))}
			</Row>
		</DadosCadastraisSection>
	);
};
