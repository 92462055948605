import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { DadosCadastrais } from './dadosCadastrais';
import { Dashboard } from './dashboard';
import { TelaParticipante } from './participante';

export const Admin = memo(() => {
	return (
		<Routes>
			<Route path='/' element={<Dashboard />} />
			<Route path='/participantes' element={<TelaParticipante />} />
			<Route path='/dados-cadastrais/:participacaoId' element={<DadosCadastrais />} />
		</Routes>
	);
});
