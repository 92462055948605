import { Grid } from 'antd';

const { useBreakpoint } = Grid;

/**
 * Hook que informa se a tela atual é mobile ou não.
 *
 * Baseia-se no hook `useBreakpoint` da lib `antd`.
 * @returns `boolean`
 */
export const useIsMobile = () => {
	const breakpoints = useBreakpoint();
	// Caso estejamos no breakpoint Large, já consideramos que é uma tela Desktop
	const isDesktop = breakpoints.lg;

	// Valor 'default' é false
	const isMobile = isDesktop === undefined ? false : !isDesktop;

	return isMobile;
};
