import { Flex } from '@jcm/design-system';

import { ErrorBoundary } from 'components';
import { Telefones } from './telefones';
import { Emails } from './email';
import { Enderecos } from './endereco';

export const Contatos = () => {
	return (
		<Flex vertical style={{ gap: 20 }}>
			<ErrorBoundary>
				<Telefones />
			</ErrorBoundary>
			<ErrorBoundary>
				<Emails />
			</ErrorBoundary>
			<ErrorBoundary>
				<Enderecos />
			</ErrorBoundary>
		</Flex>
	);
};
