import { Button, Flex, Icons, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { useIsMobile } from 'hooks';

import { ErrorBoundary } from './feedback';

type PaginaParticipanteProps = {
	id?: string;
	className?: string;
	style?: React.CSSProperties;
	title?: React.ReactNode;
	icon?: React.ReactNode;
	addon?: React.ReactNode;
	children?: React.ReactNode;

	returnBtn?: boolean;
	onReturn?: () => void;
};
export const PaginaParticipante = memo<PaginaParticipanteProps>(
	({ title, icon, addon, returnBtn, onReturn, children, ...props }) => {
		const isMobile = useIsMobile();
		const { shapes } = useTheme();

		return (
			<ErrorBoundary>
				<Flex gap='1.5rem' vertical {...props}>
					{!isMobile && (!!title || !!icon) && (
						<Flex gap={shapes.sizes.small} align='center' justify='space-between' style={{ userSelect: 'none' }}>
							<Flex align='center' justify='start' gap={shapes.sizes.small}>
								{returnBtn && (
									<Button type='filled-tonal' icon={<Icons.KeyboardArrowLeft />} circle onClick={onReturn} />
								)}

								<Typography.Title
									size='large'
									variant='primary'
									style={{ display: 'flex', alignItems: 'end', fontWeight: 500 }}
								>
									{icon}
								</Typography.Title>

								<Typography.Title size='large' variant='primary' style={{ fontWeight: 500 }}>
									{title}
								</Typography.Title>
							</Flex>

							{addon}
						</Flex>
					)}

					<Flex vertical={isMobile} gap={shapes.sizes.medium} style={{ display: isMobile ? 'flex' : 'block' }}>
						{isMobile && addon}

						<div>{children}</div>
					</Flex>
				</Flex>
			</ErrorBoundary>
		);
	},
);
