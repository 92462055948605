import { Col, Flex, Row, useTheme } from '@jcm/design-system';
import { nanoid } from 'nanoid';
import { memo } from 'react';

import { getCoresPorTipoDeDado } from '..';
import { TiposDeDado } from '../graficos';

type OptionType = TiposDeDado;

interface IOpcaoSeletorCaixasProps {
	name: string;
	fill: string;
	isSelected: boolean;

	onClick: () => void;
}
const OpcaoSeletorCaixas = memo<IOpcaoSeletorCaixasProps>(({ name, fill, isSelected, onClick }) => {
	const { elevation, shapes, colors } = useTheme();

	return (
		<Flex
			gap={shapes.sizes.medium}
			style={{
				padding: shapes.sizes.small,
				borderRadius: shapes.sizes.extraSmall,
				boxShadow: elevation[1],
				backgroundColor: isSelected ? colors.secondaryContainer : colors.containerLowest,
				cursor: 'pointer',
				userSelect: 'none',
			}}
			onClick={onClick}
		>
			<div style={{ height: '1.25rem', width: '1.25rem', backgroundColor: fill, borderRadius: '50%' }} />
			{name}
		</Flex>
	);
});

interface ISeletorCaixasProps {
	value: OptionType[];
	options: OptionType[];
	onChange: (selected: OptionType[]) => void;
}
export const SeletorCaixas = memo<ISeletorCaixasProps>(({ options, value, onChange }) => {
	const { colors } = useTheme();

	const coresPorTipoDeDado = getCoresPorTipoDeDado(colors);

	/**
	 * Função que adiciona uma opção aos valores selecionados e roda `onChange` com o novo valor
	 */
	const selecionarOpcao = (opcao: OptionType) => {
		const novosValoresSelecionados = [...value, opcao];

		return onChange(novosValoresSelecionados);
	};

	/**
	 * Função que remove uma opção dos valores selecionados e roda `onChange` com o novo valor
	 */
	const removerOpcao = (opcao: OptionType) => {
		// Mantemos todas as opções DIFERENTES da que queremos remover
		const novosValoresSelecionados = value.filter((item) => item !== opcao);

		return onChange(novosValoresSelecionados);
	};

	return (
		<Row gutter={[16, 16]}>
			{options.map((option) => {
				// Uma opção está selecionada quando ela está presente na lista de valores
				const isSelected = value.includes(option);

				// Está selecionada = clicar remove. Não está selecionada = clicar seleciona
				const onClick = () => (isSelected ? removerOpcao(option) : selecionarOpcao(option));

				const fill = coresPorTipoDeDado[option];

				return (
					// Podemos ajustar a largura de cada opção pelas props dessa Col
					<Col key={nanoid()} xl={4} xxl={3}>
						<OpcaoSeletorCaixas name={option} fill={fill} isSelected={isSelected} onClick={onClick} />
					</Col>
				);
			})}
		</Row>
	);
});
