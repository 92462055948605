type FormatType = 'cpf' | 'cep';

/**
 * Classe responsável por abrigar os métodos relacionados à formatação de texto
 */
export class StringFormatter {
	private static formatCep = (value: string) => {
		if (value.length !== 8) return value;

		const ss1 = value.substring(0, 2);
		const ss2 = value.substring(2, 5);
		const ss3 = value.substring(5, 8);

		return `${ss1}.${ss2}-${ss3}`;
	};

	private static formatCpf = (value: string) => {
		const cpfString = value.padStart(11, '0');

		const ss1 = cpfString.substring(0, 3);
		const ss2 = cpfString.substring(3, 6);
		const ss3 = cpfString.substring(6, 9);
		const ss4 = cpfString.substring(9);

		return `${ss1}.${ss2}.${ss3}-${ss4}`;
	};

	static format = (value?: string, format?: FormatType) => {
		if (!value) return value?.toString();

		switch (format) {
			case 'cep':
				return this.formatCep(value.toString());
			case 'cpf': {
				return this.formatCpf(value.toString());
			}
		}
	};
}
