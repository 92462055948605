import { Col, Row, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { ParticipacoesDadosContatoEndereco } from 'api';
import { useParticipacoesContatoQuery } from 'queries/participante';

import { DadosCadastraisSection } from '../../../cards';

const Endereco = memo<{ dadosEndereco: ParticipacoesDadosContatoEndereco; index: number }>(
	({ dadosEndereco, index }) => {
		const { colors } = useTheme();

		return (
			<>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
					<Typography.Title size='small' strong style={{ color: colors.tertiary }}>
						Endereço {index + 1}
					</Typography.Title>

					<Row gutter={[16, 16]} style={{ justifyContent: 'space-between' }}>
						<Col xs={24} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								CEP
							</Typography.Label>
							<Typography.Body>{dadosEndereco.cep}</Typography.Body>
						</Col>

						<Col xs={24} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Número
							</Typography.Label>
							<Typography.Body>{dadosEndereco.numero}</Typography.Body>
						</Col>
						<Col xs={24} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Bairro
							</Typography.Label>
							<Typography.Body>{dadosEndereco.bairro}</Typography.Body>
						</Col>
						<Col xs={24} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Estado
							</Typography.Label>
							<Typography.Body>{dadosEndereco.uf}</Typography.Body>
						</Col>
					</Row>

					<Row gutter={[16, 16]} style={{ justifyContent: 'space-between' }}>
						<Col xs={24} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Logradouro
							</Typography.Label>
							<Typography.Body>{dadosEndereco.endereco}</Typography.Body>
						</Col>

						<Col xs={24} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Complemento
							</Typography.Label>
							<Typography.Body>{dadosEndereco.complemento}</Typography.Body>
						</Col>
						<Col xs={24} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								Cidade
							</Typography.Label>
							<Typography.Body>{dadosEndereco.municipio}</Typography.Body>
						</Col>
						<Col xs={24} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography.Label size='large' style={{ color: colors.tertiary }}>
								País
							</Typography.Label>
							<Typography.Body>{dadosEndereco.pais}</Typography.Body>
						</Col>
					</Row>
				</div>
			</>
		);
	},
);
export const Enderecos: React.FC = () => {
	// Extrair id do participante da URL
	const params = useParams<{ participacaoId: string }>();
	const { data } = useParticipacoesContatoQuery(params.participacaoId as string);

	return (
		<DadosCadastraisSection title='Endereços'>
			<Row gutter={[16, 16]}>
				{data?.enderecos.map((dadosEndereco, index) => (
					<Col key={dadosEndereco.id} xs={24} style={{ gap: 250 }}>
						<Endereco dadosEndereco={dadosEndereco} index={index} />
					</Col>
				))}
			</Row>
		</DadosCadastraisSection>
	);
};
