import { memo } from 'react';

import { useVisibilityContext } from 'context';
import { NumberFormatter } from 'lib';

type PlaceholderProps = React.HtmlHTMLAttributes<HTMLSpanElement>;

const Placeholder = memo<PlaceholderProps>(({ ...props }) => (
	<span style={{ userSelect: 'none' }} {...props}>
		.....
	</span>
));

const CurrencyPlaceholder = memo<PlaceholderProps>(({ ...props }) => {
	return (
		<span style={{ whiteSpace: 'nowrap' }} {...props}>
			R$ <Placeholder />
		</span>
	);
});

const PercentPlaceholder = memo<PlaceholderProps>(({ ...props }) => {
	return (
		<>
			<Placeholder {...props} />%
		</>
	);
});

interface IDataPlaceholder {
	type?: 'default' | 'currency' | 'percent';
	children?: React.ReactNode;
}
export const DataPlaceholder = memo<IDataPlaceholder>(({ type = 'default', children }) => {
	const { isVisible } = useVisibilityContext();
	let placeholder: React.JSX.Element;

	switch (type) {
		case 'default':
			placeholder = <Placeholder data-testid='Placeholder' />;
			break;
		case 'currency':
			placeholder = <CurrencyPlaceholder data-testid='CurrencyPlaceholder' />;
			break;
		case 'percent':
			placeholder = <PercentPlaceholder data-testid='PercentPlaceholder' />;
	}

	return <>{isVisible ? children : placeholder}</>;
});

export const getValueOrDataPlaceholder = (value?: React.ReactNode) => {
	return <DataPlaceholder>{value}</DataPlaceholder>;
};

export const getCurrencyOrDataPlaceholder = (value?: React.ReactNode) => {
	if (typeof value !== 'number') return value;

	return <DataPlaceholder type='currency'>{NumberFormatter.format(value, 'BRL')}</DataPlaceholder>;
};

export const getValueOrPercentPlaceholder = (value?: React.ReactNode) => {
	if (typeof value !== 'number') return value;

	return <DataPlaceholder type='percent'>{NumberFormatter.format(value, '%')}</DataPlaceholder>;
};
