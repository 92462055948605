import { Tabs as DSTabs, ITabsProps } from '@jcm/design-system';
import { useIsMobile } from 'hooks';
import { DadosGerais } from './components';

export const Plano = () => {
	const isMobile = useIsMobile();

	const paginas: ITabsProps['items'] = [
		{
			key: 'dadosGerais',
			label: 'Dados Gerais',
			children: <DadosGerais />,
			disabled: false,
		},
		{
			key: 'saldo',
			label: 'Saldo',
			children: <div>conteudo2</div>,
			disabled: false,
		},
		{
			key: 'contribuicao',
			label: 'Contribuição',
			children: <div>conteudo3</div>,
			disabled: false,
		},
		{
			key: 'beneficios',
			label: 'Benefícios',
			children: <div>conteudo4</div>,
			disabled: false,
		},
		{
			key: 'rentabilidade',
			label: 'Rentabilidade',
			children: <div>conteudo5</div>,
			disabled: false,
		},
	];

	return (
		<DSTabs
			tabPosition={isMobile ? 'top' : 'left'}
			defaultActiveKey='dadosGerais'
			items={paginas.map((item) => {
				return { ...item, children: item.children };
			})}
		/>
	);
};
