import { useMemo } from 'react';

import { checkObjectForProperty, checkValueForType } from 'lib';

export type ApiConfig = {
	url: string;
	clienteId: number | string;
};

export type KeycloakConfig = {
	url: string;
	devUrl: string;
	realm: string;
	participanteClientId: string;
	adminClientId: string;
};

export type ThemeVariantConfig = {
	brandLogo: string;
};

export type ThemeConfig = {
	light: ThemeVariantConfig;
	dark: ThemeVariantConfig;
};

export type GlobalConfig = {
	themeConfig?: ThemeConfig;
	keycloakConfig: KeycloakConfig;
	apiConfig: ApiConfig;
};

const validateGlobalConfig = (config: Record<string, object>) => {
	// Função que checa a existência de uma lista de propriedades de um objeto
	const validateConfig = (config: object, properties: string[]) => {
		return properties.every((property) => checkObjectForProperty(config, property));
	};

	// Propriedades esperadas dentro do arquivo de config
	const expectedProperties = [
		{ name: 'keycloakConfig', properties: ['url', 'devUrl', 'realm', 'participanteClientId', 'adminClientId'] },
		{ name: 'apiConfig', properties: ['url', 'clienteId'] },
	];

	expectedProperties.forEach(({ name, properties }) => {
		// Checa se as props existem
		if (!checkObjectForProperty(config, name)) {
			throw new Error(`[useGlobalConfigFile] ${name} ausente no arquivo de configuração!`);
		}

		const obj = config[name];

		// Checa se são objetos
		if (!checkValueForType(obj, 'object')) {
			throw new Error(`[useGlobalConfigFile] ${name} não é um objeto!`);
		}

		// Checa se as props existem dentro do objeto
		if (!validateConfig(obj, properties)) {
			throw new Error(`[useGlobalConfigFile] Configuração ${name} com formato inválido!`);
		}
	});

	return true;
};

export const useGlobalConfigFile = () => {
	const globalConfig = useMemo<GlobalConfig>(() => {
		// Variável definida em '/public/config.js'
		// @ts-ignore
		if (!validateGlobalConfig(publicAppConfig)) throw new Error('Arquivo de configuração inválido!');

		// @ts-ignore
		return publicAppConfig;
	}, []);

	return globalConfig;
};
