type RotaAdmin = 'dashboard' | 'participantes' | 'campanhas' | 'requerimentos' | 'dadosCadastrais' | 'sac';

/**
 * Classe que abriga as rotas principais do portal do admin, pra facilitar a navegação
 *
 * Rotas dinâmicas não são incluídas aqui. Rotas não são matchers.
 */
export class RotasAdmin {
	static readonly rotas: Record<RotaAdmin, string> = {
		dashboard: '/admin',
		participantes: '/admin/participantes',
		campanhas: '/admin/campanhas',
		requerimentos: '/admin/requerimentos',
		sac: '/admin/sac',
		dadosCadastrais: '/admin/dados-cadastrais',
	};

	static get = (base: RotaAdmin, ...args: string[]) => {
		return [this.rotas[base], ...args].join('/');
	};
}
