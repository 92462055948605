import { Document, Page, StyleSheet, View } from '@react-pdf/renderer';

import { Header } from './Header';

const styles = StyleSheet.create({
	document: {
		fontSize: 12,
	},

	page: {},

	container: {
		padding: 16,
		paddingTop: 0,
		width: '100%',
	},

	body: {
		width: '100%',
	},

	row: {
		display: 'flex',
		flexDirection: 'row',
	},

	col: {
		display: 'flex',
		flexDirection: 'column',
	},
});

type TemplateProps = {
	title?: React.ReactNode;
	subTitle?: React.ReactNode;
	children?: React.ReactNode;
};

export const Template: React.FC<TemplateProps> = ({ title, subTitle, children }) => {
	return (
		<Document style={styles.document}>
			<Page size='A4' style={styles.page} wrap>
				<View style={styles.container}>
					<Header title={title} subTitle={subTitle} />

					<View style={styles.body}>{children}</View>
				</View>
			</Page>
		</Document>
	);
};
