import { Skeleton, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { CustomTitleTable } from 'components';
import { useThemeContext } from 'context';

import { ContribuicaoType, columns } from './TabelaExtratoMovimentacao';

import styles from './index.module.scss';

export const TabelaExtratoMovimentacaoLoading = memo(() => {
	const { currentTheme } = useThemeContext();
	const { colors } = useTheme();

	return (
		<CustomTitleTable<ContribuicaoType>
			tableVariant='tertiary'
			containerClassName={currentTheme === 'light' ? styles.lightTableContainer : styles.darkTableContainer}
			headerStyle={{ backgroundColor: colors.secondary, color: colors.onSecondary }}
			columns={columns}
			dataSource={[]}
			loading={true}
			title={
				<Typography.Label variant='default' size='large' style={{ padding: '0.25rem 1rem', fontWeight: 500 }}>
					<Skeleton.Input size='small' active />
				</Typography.Label>
			}
		/>
	);
});
