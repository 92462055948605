import { Flex, IRadioGroupProps, ISelectProps, RadioGroup, RangePicker, Select, useTheme } from '@jcm/design-system';
import { memo } from 'react';
// TODO - Corrigir props no design system
import { RangePickerProps } from 'antd/es/date-picker';

import { useIsMobile } from 'hooks';

type OptionType = { label: React.ReactNode; value: string | number };
export type SeletorPeriodoProps = {
	id?: string;
	style?: React.CSSProperties;
	vertical?: boolean;

	isPickerOpen: boolean;
	picker?: RangePickerProps['picker'];
	pickerValue?: RangePickerProps['value'];
	pickerOnChange?: RangePickerProps['onChange'];
	pickerFormat?: RangePickerProps['format'];
	pickerDisabledDate?: RangePickerProps['disabledDate'];

	radioOptions?: OptionType[];
	radioValue?: IRadioGroupProps['value'] | ISelectProps['value'];
	radioOnChange?: (value: IRadioGroupProps['value']) => void;
};

export const SeletorPeriodo = memo<SeletorPeriodoProps>(
	({
		vertical,
		radioOptions,
		radioValue,
		radioOnChange,
		pickerValue,
		pickerOnChange,
		pickerFormat,
		pickerDisabledDate,
		isPickerOpen,
		picker = 'year',
		id,
		style,
	}) => {
		const { shapes } = useTheme();

		const isMobile = useIsMobile();
		const isVertical = isMobile || vertical;
		const baseRadioProps = { value: radioValue, options: radioOptions };

		const selectNode = isMobile ? (
			<Select {...baseRadioProps} variant='filled' onChange={(e) => radioOnChange?.(e)} style={{ minWidth: '150px' }} />
		) : (
			<RadioGroup {...baseRadioProps} onChange={(e) => radioOnChange?.(e.target.value)} optionType='button' />
		);

		return (
			<Flex
				id={id}
				align={isVertical ? 'start' : 'center'}
				justify='center'
				gap={isVertical ? shapes.sizes.medium : shapes.sizes.large}
				style={style}
				vertical={isVertical}
			>
				{selectNode}

				{isPickerOpen && (
					<RangePicker
						// TODO - Corrigir essa prop no design system
						//@ts-ignore
						picker={picker}
						value={pickerValue}
						allowClear={false}
						allowEmpty={[false, false]}
						placeholder={['Início', 'Fim']}
						onChange={pickerOnChange}
						format={pickerFormat}
						disabledDate={pickerDisabledDate}
					/>
				)}
			</Flex>
		);
	},
);
