export interface ICalculadoraAcumuloProps {
	saldoInicial: number;
	/** Percentual */
	retornoReal: number;
	/** Percentual */
	crescimentoReal: number;
	idadeAtual: number;
	idadeProjetada: number;
}

export class CalculadoraAcumulo {
	private readonly saldoInicial: number;
	private readonly retornoReal: number;
	private readonly crescimentoReal: number;
	private readonly anos: number;

	private validarProps({ retornoReal, crescimentoReal, idadeAtual, idadeProjetada }: ICalculadoraAcumuloProps) {
		// Evita divisão por 0
		if (retornoReal - crescimentoReal === 0) {
			throw new Error('Retorno Real e Crescimento Real não podem ter o mesmo valor!');
		}

		// Evita previsões do passado
		if (idadeProjetada - idadeAtual < 0) {
			throw new Error('Idade inválida para simulação!');
		}
	}

	constructor({ saldoInicial, retornoReal, crescimentoReal, idadeAtual, idadeProjetada }: ICalculadoraAcumuloProps) {
		this.validarProps({ saldoInicial, retornoReal, crescimentoReal, idadeAtual, idadeProjetada });

		this.saldoInicial = saldoInicial;
		this.retornoReal = retornoReal;
		this.crescimentoReal = crescimentoReal;
		const anos = idadeProjetada - idadeAtual;
		this.anos = anos;
	}

	private calcularSaldoFuturo = () => {
		return this.saldoInicial * (1 + this.retornoReal) ** this.anos;
	};

	private calcularContribuicoesFuturas = (contribuicaoMensal: number) => {
		const termo1 =
			((1 + this.retornoReal) ** this.anos - (1 + this.crescimentoReal) ** this.anos) /
			(this.retornoReal - this.crescimentoReal);

		const termo2 = ((1 + this.retornoReal) ** (1 / 12) - 1) / this.retornoReal;

		return contribuicaoMensal * termo1 * termo2;
	};

	public calcular = (contribuicaoMensal: number) => {
		const saldoFuturo = this.calcularSaldoFuturo();
		const contribuicoesFuturas = this.calcularContribuicoesFuturas(contribuicaoMensal);

		return saldoFuturo + contribuicoesFuturas;
	};
}
