import { timer } from 'lib';

export abstract class BaseApi {
	private delay = 0;
	private isTesting = process.env.NODE_ENV === 'test';

	constructor(props?: { delay?: number }) {
		if (props?.delay) this.delay = props.delay;
	}

	protected doRequest = async <T>(data?: T): Promise<T> => {
		if (!this.isTesting) await timer(this.delay);

		return data as T;
	};
}
