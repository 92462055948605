import { Container, Divider, Flex, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

export type CardRentabilidadeProps = {
	id?: string;
	className?: string;
	style?: React.CSSProperties;

	title?: React.ReactNode;
	children?: React.ReactNode;

	divider?: boolean;
};
export const CardRentabilidade = memo<CardRentabilidadeProps>(({ title, children, divider, style, ...props }) => {
	const { colors, shapes } = useTheme();

	return (
		<Container variant='default' elevation='lowest' level={1} style={{ userSelect: 'none', ...style }} {...props}>
			<Flex gap={shapes.sizes.large} style={{ height: '100%' }} vertical>
				{!!title && (
					<Typography.Title style={{ fontSize: '20px', fontWeight: 500, color: colors.onSurfaceVariant }}>
						{title}
					</Typography.Title>
				)}

				{divider && <Divider style={{ margin: 0 }} />}

				{children}
			</Flex>
		</Container>
	);
});
