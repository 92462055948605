import { ITableProps, Typography, useTheme } from '@jcm/design-system';
import { nanoid } from 'nanoid';
import { memo } from 'react';

import { CustomTitleTable } from 'components';
import { useThemeContext } from 'context';
import { NumberFormatter } from 'lib';

import styles from './index.module.scss';

type TipoType = 'Patrocinadora' | 'Participante' | 'Tipo' | 'Saldo total' | '';

export type ExtratoType = {
	// Útil pra gerar a tabela
	rowSpanTipo: number;
	colunaTipo: TipoType;

	rowSpanPerfil: number;
	colunaPerfil1?: string;
	colunaConta?: string;

	colunaValor1?: string | number;
	colunaCotas1?: string | number;

	colunaValor2?: string | number;
	colunaCotas2?: string | number;
	colunaRentabilidade?: string | number;

	colunaValor3: string | number;
	colunaCotas3?: string | number;
};
type TableType = ITableProps<ExtratoType>;

const colunaValorRender = (value: unknown) => {
	if (typeof value === 'number') return NumberFormatter.format(value, 'BRL');
	return value as React.ReactNode;
};

const colunaCotasRender = (value: unknown) => {
	if (typeof value === 'number') return NumberFormatter.format(value, 'default', { minimumFractionDigits: 4 });
	return value as React.ReactNode;
};

const colunaRentabilidadeRender = (value: string | number) => {
	if (typeof value === 'number') return NumberFormatter.format(value, 'BRL');
	return value as React.ReactNode;
};

const getOnCell = (key: keyof ExtratoType) => (data: ExtratoType) => {
	const value = data[key];

	return {
		style: { textAlign: typeof value === 'number' ? 'end' : 'start', whiteSpace: 'nowrap' } as React.CSSProperties,
	};
};

const onTipoCell = (data: ExtratoType) => ({ rowSpan: data['rowSpanTipo'], className: styles.tdTipo });

const onPerfilCell = (data: ExtratoType) => ({ rowSpan: data['rowSpanPerfil'] });

export type TabelaExtratoSaldoProps = {
	title: string;
	dataSaldoAnterior?: React.ReactNode;
	dataSaldoAtual?: React.ReactNode;
	id?: string;
	data?: ExtratoType[];
	isLoading?: boolean;
};
export const TabelaExtratoSaldo = memo<TabelaExtratoSaldoProps>(
	({ title, dataSaldoAnterior, dataSaldoAtual, data, id, isLoading }) => {
		const { currentTheme } = useThemeContext();
		const { colors } = useTheme();

		const extratoSaldoColumns: TableType['columns'] = [
			{
				key: 'colunaTipo',
				dataIndex: 'colunaTipo',
				className: styles.colunaFixa,
				onCell: onTipoCell,
			},

			{
				title: 'Saldo anterior',
				key: 'colunaPerfil1',
				dataIndex: 'colunaPerfil1',
				onCell: onPerfilCell,
			},

			{
				title: dataSaldoAnterior,
				key: 'colunaConta',
				dataIndex: 'colunaConta',
				onCell: getOnCell('colunaConta'),
			},

			{
				title: '',
				key: 'colunaValor1',
				dataIndex: 'colunaValor1',
				render: colunaValorRender,
				onCell: getOnCell('colunaValor1'),
			},
			{
				title: '',
				key: 'colunaCotas1',
				dataIndex: 'colunaCotas1',
				render: colunaCotasRender,
				onCell: getOnCell('colunaCotas1'),
			},

			{
				title: 'Movimento',
				key: 'colunaValor2',
				dataIndex: 'colunaValor2',
				className: styles.tdComBorda,
				render: colunaValorRender,
				onCell: getOnCell('colunaValor2'),
			},
			{
				title: '',
				key: 'colunaCotas2',
				dataIndex: 'colunaCotas2',
				render: colunaCotasRender,
				onCell: getOnCell('colunaCotas2'),
			},
			{
				title: '',
				key: 'colunaRentabilidade',
				dataIndex: 'colunaRentabilidade',
				render: colunaRentabilidadeRender,
				onCell: getOnCell('colunaRentabilidade'),
			},

			{
				title: 'Saldo atual',
				key: 'colunaValor3',
				dataIndex: 'colunaValor3',
				className: styles.tdComBorda,
				render: colunaValorRender,
				onCell: getOnCell('colunaValor3'),
			},
			{
				title: dataSaldoAtual,
				key: 'colunaCotas3',
				dataIndex: 'colunaCotas3',
				render: colunaCotasRender,
				onCell: getOnCell('colunaCotas3'),
			},
		];

		const tableRowClassName: TableType['rowClassName'] = (record) => {
			const classNames: string[] = [styles.linha];

			// Linha de totais não tem perfil nem quotas
			const isLinhaTotal = !record.colunaPerfil1 && !record.colunaCotas1;
			if (isLinhaTotal && record.colunaTipo !== 'Saldo total') classNames.push(styles.trTotalParcial);

			switch (record.colunaTipo) {
				case 'Tipo':
					classNames.push(styles.trPerfilConta);
					break;

				case 'Participante':
					classNames.push(styles.trSaldoParticipante);
					break;

				case 'Patrocinadora':
					classNames.push(styles.trSaldoPatrocinadora);
					break;

				case 'Saldo total':
					classNames.push(styles.trSaldoTotal);
					break;
			}

			return classNames.join(' ');
		};

		return (
			<CustomTitleTable<ExtratoType>
				id={id}
				containerClassName={currentTheme === 'light' ? styles.lightTableContainer : styles.darkTableContainer}
				headerStyle={{ backgroundColor: colors.secondary, color: colors.onSecondary }}
				wrapperClassName={currentTheme === 'light' ? styles.lightWrapper : styles.darkWrapper}
				columns={extratoSaldoColumns}
				dataSource={data ?? []}
				rowKey={() => nanoid()}
				rowClassName={tableRowClassName}
				loading={isLoading}
				title={
					<Typography.Label variant='default' size='large' style={{ padding: '0.25rem 1rem', fontWeight: 500 }}>
						{title}
					</Typography.Label>
				}
			/>
		);
	},
);
