import { Flex, Icons, Typography, useTheme } from '@jcm/design-system';
import { memo, useEffect, useState } from 'react';

import { ErrorBoundary } from 'components';
import { useIsMobile } from 'hooks';

import { Header } from './filtro';
import { Resultados } from './resultados';

export type TelaParticipanteProps = {
	id?: string;
	className?: string;
};

export const TelaParticipante = memo<TelaParticipanteProps>(({ ...props }) => {
	const [termoPesquisado, setTermoPesquisado] = useState<string>('');
	const [termoPesquisadoComAtraso, setTermoPesquisadoComAtraso] = useState<string>('');

	const { colors, shapes } = useTheme();
	const isMobile = useIsMobile();

	useEffect(() => {
		const timeoutId = setTimeout(() => setTermoPesquisadoComAtraso(termoPesquisado), 500);

		return () => clearTimeout(timeoutId);
	}, [termoPesquisado]);

	const handleTermoPesquisadoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTermoPesquisado(e.target.value);
	};

	return (
		<Flex vertical gap={shapes.sizes.extraLarge} {...props}>
			<Flex
				vertical
				gap={shapes.sizes.medium}
				style={{
					padding: shapes.sizes.large,
					margin: '-1rem',
					position: 'sticky',
					top: isMobile ? '56px' : '70px',
					backgroundColor: isMobile ? colors.surface : colors.containerLow,
					zIndex: 1000,
				}}
			>
				<Flex gap={shapes.sizes.small}>
					<Typography.Title size='large' variant='primary' style={{ fontWeight: 500 }}>
						<Icons.Person variant='outlined' />
					</Typography.Title>
					<Typography.Title size='large' variant='primary' style={{ fontWeight: 500 }}>
						Participantes
					</Typography.Title>
				</Flex>
				<Header value={termoPesquisado} onChange={handleTermoPesquisadoChange} />
			</Flex>

			<ErrorBoundary>
				<Resultados filtro={termoPesquisadoComAtraso} />
			</ErrorBoundary>
		</Flex>
	);
});
