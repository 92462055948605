import { createContext, memo, useCallback, useContext, useMemo, useState } from 'react';

export interface IVisibilityContext {
	isVisible: boolean;
	toggleVisibility: () => void;
}

// eslint-disable-next-line
const VisibilityContext = createContext<IVisibilityContext>(undefined!);

export const useVisibilityContext = () => useContext(VisibilityContext);

type Props = { children: React.ReactNode };
export const VisibilityContextProvider = memo<Props>(({ children }) => {
	const [isVisible, setIsVisible] = useState(false);

	const toggleVisibility = useCallback(() => setIsVisible((prev) => !prev), []);

	const context = useMemo<IVisibilityContext>(() => {
		return { isVisible, toggleVisibility };
	}, [isVisible, toggleVisibility]);

	return <VisibilityContext.Provider value={context}>{children}</VisibilityContext.Provider>;
});
