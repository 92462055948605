import { GetFn } from 'api';
import { BaseApi } from './BaseApi';

// TODO - Puxar essas info de alguma API ou lib, não de um arquivo .json
import phoneCodes from 'assets/phone.json';

export type GetCountryCodeReturnType = typeof phoneCodes;

export interface IPhoneApi {
	/**
	 * https://country.io/phone.json
	 */
	getCountryCodes: GetFn<GetCountryCodeReturnType>;
}

export class PhoneApi extends BaseApi implements IPhoneApi {
	public getCountryCodes = async () => phoneCodes;
}
