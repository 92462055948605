import { Spin } from '@jcm/design-system';
import { memo } from 'react';

import { useThemeContext } from 'context';

import styles from './index.module.scss';

type LoadingProps = { loading: boolean };
export const Loading = memo<LoadingProps>(({ loading }) => {
	const { currentTheme } = useThemeContext();

	return (
		<Spin
			variant='default'
			size='large'
			spinning={loading}
			fullscreen
			className={currentTheme === 'light' ? styles.spinLight : styles.spinDark}
		/>
	);
});
