import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type BaseGetFnArgs<TData> = [string] | [string, AxiosRequestConfig<TData>];
type BaseSendFnArgs<TPayload> =
	| [string, TPayload | undefined]
	| [string, TPayload | undefined, AxiosRequestConfig<TPayload>];

/**
 * Classe base pras classes de API.
 *
 * Não instanciar!
 */
export abstract class BaseApi {
	private api: AxiosInstance;
	private clienteId?: number | string;

	constructor(props: { api: AxiosInstance; clienteId?: number | string }) {
		this.api = props.api;
		this.clienteId = props.clienteId;

		return this;
	}

	/**
	 * @param url Url base.
	 * @returns Url tratado, com `clienteId` adicionado (caso necessário).
	 */
	private readonly getRequestUrl = (url: string) => {
		// Caso clienteId seja definido, adicionamos à url
		const requestUrl = this.clienteId ? `/${this.clienteId}${url}` : url;

		return requestUrl;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `GET`.
	 *
	 * @param url Endereço do recurso.
	 * @returns Promise que resolve para um valor `TResponse`.
	 */
	protected readonly getRequest = async <TData, TResponse = TData>(...[url, config]: BaseGetFnArgs<TData>) => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.get<TData, AxiosResponse<TResponse>, TData>(requestUrl, config);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `DELETE`.
	 *
	 * @param url Endereço do recurso.
	 * @returns Promise que resolve para um valor `TResponse`.
	 */
	protected readonly deleteRequest = async <TData, TResponse = TData>(...[url, config]: BaseGetFnArgs<TData>) => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.delete<TData, AxiosResponse<TResponse>, TData>(requestUrl, config);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `HEAD`.
	 *
	 * @param url Endereço do recurso.
	 * @returns Promise que resolve para um valor `TResponse`.
	 */
	protected readonly headRequest = async <TData, TResponse = TData>(...[url, config]: BaseGetFnArgs<TData>) => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.head<TData, AxiosResponse<TResponse>, TData>(requestUrl, config);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `OPTIONS`.
	 *
	 * @param url Endereço do recurso.
	 * @returns Promise que resolve para um valor `TResponse`.
	 */
	protected readonly optionsRequest = async <TData, TResponse = TData>(...[url, config]: BaseGetFnArgs<TData>) => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.options<TData, AxiosResponse<TResponse>, TData>(requestUrl, config);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `POST`.
	 *
	 * @param url Endereço do recurso.
	 * @param payload Payload da requisição, com tipo `TPayload`.
	 * @returns Promise que resolve para um valor `K`. Por padrão, `K = T`
	 */
	protected readonly postRequest = async <TPayload, TData = void>(
		...[url, payload, config]: BaseSendFnArgs<TPayload>
	) => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.post<TPayload, AxiosResponse<TData>, TPayload>(requestUrl, payload, config);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `PUT`.
	 *
	 * @param url Endereço do recurso.
	 * @param payload Payload da requisição, com tipo `T`.
	 * @returns Promise que resolve para um valor `K`. Por padrão, `K = T`
	 */
	protected readonly putRequest = async <TPayload, TData = void>(
		...[url, payload, config]: BaseSendFnArgs<TPayload>
	) => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.put<TPayload, AxiosResponse<TData>, TPayload>(requestUrl, payload, config);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `PATCH`.
	 *
	 * @param url Endereço do recurso.
	 * @param payload Payload da requisição, com tipo `T`.
	 * @returns Promise que resolve para um valor `K`. Por padrão, `K = T`
	 */
	protected readonly patchRequest = async <TPayload, TData = void>(
		...[url, payload, config]: BaseSendFnArgs<TPayload>
	) => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.patch<TPayload, AxiosResponse<TData>, TPayload>(requestUrl, payload, config);

		return data;
	};
}
