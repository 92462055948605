import { DrawerBarItem, FloatButton, Icons, useTheme } from '@jcm/design-system';
import { Outlet } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';

import { ErrorBoundary } from 'components';
import { useIsMobile } from 'hooks';
import { RotasAdmin } from 'routes/RotasAdmin';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

export interface ILayoutAdminVariantProps {
	id?: string;
	children?: React.ReactNode;

	drawerBarItems: DrawerBarItem[];
}

export const LayoutAdmin: React.FC<{ id?: string }> = ({ id }) => {
	const { colors } = useTheme();

	// Nome da aba
	useDocumentTitle('Portal do Administrador');

	const isMobile = useIsMobile();
	const LayoutComponent: React.FC<ILayoutAdminVariantProps> = isMobile ? Mobile : Desktop;

	const basePath = '/admin';
	const drawerBarItems = [
		{ label: 'Dashboard', key: RotasAdmin.get('dashboard'), icon: <Icons.Home variant='outlined' /> },
		{ label: 'Participantes', key: RotasAdmin.get('participantes'), icon: <Icons.Person variant='outlined' /> },
		{
			label: 'Campanhas',
			key: RotasAdmin.get('campanhas'),
			icon: <Icons.Campaign variant='outlined' />,
			disabled: true,
		},
		{
			label: 'Requerimentos',
			key: RotasAdmin.get('requerimentos'),
			icon: <Icons.ReceiptLong variant='outlined' />,
			disabled: true,
		},

		{ label: 'SAC', key: `${basePath}/sac`, icon: <Icons.Help variant='outlined' />, disabled: true },
	];

	return (
		<ErrorBoundary>
			<LayoutComponent id={id} drawerBarItems={drawerBarItems}>
				<div style={{ padding: '0.5rem 1rem', color: colors.onSurface }}>
					<Outlet />

					<FloatButton.BackTop />
				</div>
			</LayoutComponent>
		</ErrorBoundary>
	);
};
