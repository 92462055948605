import { Flex, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import noData from 'assets/images/noData.svg';

interface ISemDadosProps {
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	footer?: React.ReactNode;

	style?: React.CSSProperties;
}
export const SemDados = memo<ISemDadosProps>(({ title, subtitle, footer, style }) => {
	const { shapes, colors } = useTheme();

	return (
		<Flex
			gap={shapes.sizes.large}
			align='center'
			vertical
			style={{ userSelect: 'none', ...style }}
			data-testid='SemDados'
		>
			<img src={noData} alt='Sem dados' style={{ maxWidth: '90%' }} />

			<Typography.Headline size='small'>{title}</Typography.Headline>

			<Typography.Title style={{ color: colors.outline }}>{subtitle}</Typography.Title>

			{footer}
		</Flex>
	);
});
