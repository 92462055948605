import { Skeleton, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { CustomTitleTable } from 'components';

import { FichaFinanceiraDataType, fichaFinanceiraColumns } from './TabelaFichaFinanceira';

export const TabelaFichaFinanceiraLoading = memo(() => {
	const { colors } = useTheme();

	return (
		<CustomTitleTable<FichaFinanceiraDataType>
			tableVariant='tertiary'
			headerStyle={{ backgroundColor: colors.tertiary, color: colors.onTertiary }}
			columns={fichaFinanceiraColumns}
			dataSource={[]}
			loading={true}
			title={
				<Typography.Label variant='default' size='large'>
					<Skeleton.Input size='small' active />
				</Typography.Label>
			}
		/>
	);
});
