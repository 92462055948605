import 'dayjs/locale/pt-br';

import {
	DatePicker,
	Flex,
	IDatePickerProps,
	IRadioGroupProps,
	ISelectProps,
	ITextInputProps,
	RadioGroup,
	Select,
	Skeleton,
	TextInput,
	Typography,
	useTheme,
} from '@jcm/design-system';
// TODO - Corrigir props no design system
import { DatePickerProps, SkeletonProps } from 'antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { Dayjs } from 'dayjs';
import { memo, useCallback } from 'react';

import { DateFormatter, FullDateFormat } from 'lib';

import styles from './index.module.scss';

export interface IInputEntryProps<T extends ITextInputProps | IRadioGroupProps | ISelectProps | IDatePickerProps> {
	label: React.ReactNode;
	formatter?: (value?: T['value']) => React.ReactNode;

	inputProps?: Partial<T>;
	isLoading?: boolean;
	isEditable?: boolean;

	id: string;
	name?: string;
	className?: string;
	style?: React.CSSProperties;
}

interface ITextInputEntryProps extends IInputEntryProps<ITextInputProps> {
	value?: string | number;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}
export const TextInputEntry = memo<ITextInputEntryProps>(
	({ isLoading, isEditable, name, label, value, inputProps, onChange, formatter, ...props }) => {
		const { shapes } = useTheme();

		const getFormattedValue = useCallback((value?: string | number) => formatter?.(value) ?? value, [formatter]);

		const getValueOrSkeleton = useCallback(
			(value: React.ReactNode, skeletonProps?: SkeletonProps) => {
				if (isLoading) return <Skeleton.Input active {...skeletonProps} />;

				return value ?? '-';
			},
			[isLoading],
		);

		return (
			<Flex gap={shapes.sizes.extraSmall} vertical {...props}>
				<Typography.Body size='medium' variant='default'>
					{label}
				</Typography.Body>

				<Typography.Body size='medium' variant='default' style={{ fontWeight: 600 }}>
					{getValueOrSkeleton(
						isEditable ? (
							<TextInput type='text' variant='outlined' value={value} onChange={onChange} name={name} {...inputProps} />
						) : (
							getFormattedValue(value)
						),
						{ style: { height: shapes.sizes.large } },
					)}
				</Typography.Body>
			</Flex>
		);
	},
);

interface IRadioInputEntryProps extends IInputEntryProps<IRadioGroupProps> {
	options?: IRadioGroupProps['options'];
	value?: IRadioGroupProps['value'];
	onChange?: IRadioGroupProps['onChange'];
}
export const RadioInputEntry = memo<IRadioInputEntryProps>(
	({ isLoading, isEditable, name, label, options, value, inputProps, onChange, ...props }) => {
		const { shapes } = useTheme();

		const getValueOrSkeleton = useCallback(
			(value: React.ReactNode, skeletonProps?: SkeletonProps) => {
				if (isLoading) return <Skeleton.Input active {...skeletonProps} />;

				return value ?? 'Não informado';
			},
			[isLoading],
		);

		return (
			<Flex gap={shapes.sizes.extraSmall} vertical {...props}>
				<Typography.Body size='medium' variant='default'>
					{label}
				</Typography.Body>

				<Typography.Body size='medium' variant='default' style={{ fontWeight: 600 }}>
					{getValueOrSkeleton(
						<RadioGroup
							optionType='default'
							options={options}
							value={value}
							onChange={isEditable ? onChange : () => null}
							name={name}
							{...inputProps}
						/>,
						{ style: { height: shapes.sizes.large } },
					)}
				</Typography.Body>
			</Flex>
		);
	},
);

interface ISelectEntryProps extends IInputEntryProps<ISelectProps> {
	value?: ISelectProps['value'];
	options?: ISelectProps['options'];
	onChange?: ISelectProps['onChange'];
}
export const SelectEntry = memo<ISelectEntryProps>(
	({ isLoading, isEditable, label, options, value, inputProps, onChange, ...props }) => {
		const { shapes } = useTheme();

		const getValueOrSkeleton = useCallback(
			(value: React.ReactNode, skeletonProps?: SkeletonProps) => {
				if (isLoading) return <Skeleton.Input active {...skeletonProps} />;

				return value ?? 'Não informado';
			},
			[isLoading],
		);

		return (
			<Flex gap={shapes.sizes.extraSmall} vertical {...props}>
				<Typography.Body size='medium' variant='default'>
					{label}
				</Typography.Body>

				<Typography.Body size='medium' variant='default' style={{ fontWeight: 600 }}>
					{getValueOrSkeleton(
						isEditable ? (
							<Select variant='outlined' options={options} value={value} onChange={onChange} {...inputProps} />
						) : (
							value
						),
						{ style: { height: shapes.sizes.large } },
					)}
				</Typography.Body>
			</Flex>
		);
	},
);

interface IDatePickerEntryProps extends IInputEntryProps<IDatePickerProps> {
	value?: DatePickerProps['value'];
	onChange?: DatePickerProps['onChange'];
	dateFormat?: FullDateFormat;
}
export const DatePickerEntry = memo<IDatePickerEntryProps>(
	({ isLoading, isEditable, name, label, value, inputProps, onChange, dateFormat, className, ...props }) => {
		const { shapes } = useTheme();

		const getValueOrSkeleton = useCallback(
			(value: React.ReactNode, skeletonProps?: SkeletonProps) => {
				if (isLoading) return <Skeleton.Input active {...skeletonProps} />;

				return value ?? '-';
			},
			[isLoading],
		);

		return (
			<Flex gap={shapes.sizes.extraSmall} vertical {...props}>
				<Typography.Body size='medium' variant='default'>
					{label}
				</Typography.Body>

				<Typography.Body size='medium' variant='default' style={{ fontWeight: 600 }}>
					{getValueOrSkeleton(
						isEditable ? (
							<DatePicker
								locale={locale}
								className={`${styles.datePicker} ${className || ''}`}
								variant='outlined'
								value={value}
								onChange={onChange}
								name={name}
								allowClear={false}
								format={(date: Dayjs) => DateFormatter.format(date.toDate(), 'DD/MM/YYYY')}
								{...inputProps}
							/>
						) : (
							DateFormatter.format(value?.toDate() ?? new Date(0), dateFormat)
						),
						{ style: { height: shapes.sizes.large } },
					)}
				</Typography.Body>
			</Flex>
		);
	},
);

export * from './DatePicker';
