import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const COLORS = ['#6A4FA3', '#AF3000', '#F8961E', '#F9C74F', '#90BE6D', '#2D9CDB', '#F94144'];

const data = [
	{
		tipo: 'Tipo A',
		quantidade: 2400,
	},
	{
		tipo: 'Tipo B',
		quantidade: 1398,
	},
	{
		tipo: 'Tipo C',
		quantidade: 9800,
	},
	{
		tipo: 'Tipo D',
		quantidade: 3908,
	},
	{
		tipo: 'Tipo E',
		quantidade: 4800,
	},
	{
		tipo: 'Tipo F',
		quantidade: 3800,
	},
	{
		tipo: 'Tipo G',
		quantidade: 4300,
	},
];

export const TiposConcessoes = () => {
	console.log(data);

	return (
		<ResponsiveContainer width='100%' height={412}>
			<BarChart
				layout='vertical'
				width={500}
				height={300}
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
				barSize={12}
			>
				<YAxis padding={{ top: 40, bottom: 40 }} type='category' dataKey='tipo' scale='point' />
				<XAxis type='number' />
				<Tooltip />
				<CartesianGrid strokeDasharray='3 3' />
				<Bar dataKey='quantidade' background={{ fill: '#eee' }}>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index]} />
					))}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};
