export enum TipoContribuicaoEnum {
	Basica = 'ContribuicaoBasica',
	Adicional = 'ContribuicaoAdicional',
	Voluntária = 'ContribuicaoVoluntaria', // TODO - Confirmar se é isso mesmo
}

export interface ICalculadoraContribuicaoProps {
	tipoContribuicao: TipoContribuicaoEnum;
	/**
	 * Base do cálculo da contribuição
	 */
	salarioParticipacao: number;
	/**
	 * Percentual da contribuição que será usado para a contribuição da patrocinadora
	 */
	repique: number;
}

/**
 * Classe responsável pelos cálculos de contribuição e contrapartida.
 */
export class CalculadoraContribuicao {
	private readonly tipoContribuicao: TipoContribuicaoEnum;
	private readonly salarioParticipacao: number;
	private readonly repique: number;

	constructor(props: ICalculadoraContribuicaoProps) {
		this.tipoContribuicao = props.tipoContribuicao;
		this.salarioParticipacao = props.salarioParticipacao;
		this.repique = props.repique;
	}

	private calcularContribuicaoBasica = (percentual: number) => {
		return percentual * this.salarioParticipacao;
	};

	private calcularContribuicaoAdicional = (percentual: number) => {
		return percentual * this.salarioParticipacao;
	};

	/**
	 * @param percentual Percentual do Salário Participação que será usado na contribuição
	 * @returns Valor da contribuição em R$
	 */
	public calcularContribuicao = (percentual: number) => {
		switch (this.tipoContribuicao) {
			case TipoContribuicaoEnum.Basica:
				return this.calcularContribuicaoBasica(percentual);

			case TipoContribuicaoEnum.Adicional:
				return this.calcularContribuicaoAdicional(percentual);

			// TODO - Lógica pra contribuição voluntária
			case TipoContribuicaoEnum.Voluntária:
				return this.calcularContribuicaoAdicional(percentual);
		}
	};

	public calcularContrapartida = (contribuicao: number) => contribuicao * this.repique;
}
