import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

const data = [
	{ name: 'Fundo reversão', valor: 400 },
	{ name: 'Participante', valor: 300 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const ParticipantesFundoReversao = () => {
	console.log(data);

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: Record<string, number>) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		);
	};

	return (
		<ResponsiveContainer aspect={1} minWidth='100%' minHeight={100}>
			<PieChart>
				<Pie
					width='100%'
					height='100%'
					data={data}
					cx='50%'
					cy='50%'
					labelLine={false}
					label={renderCustomizedLabel}
					outerRadius={'70%'}
					fill='#8884d8'
					dataKey='valor'
				>
					{data.map((entry, index) => (
						<Cell fontSize={50} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
				<Legend iconType='circle' verticalAlign='bottom' height={36} />
			</PieChart>
		</ResponsiveContainer>
	);
};
