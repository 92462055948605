import { Badge, Button, Card, Flex, Icons, Typography, useTheme } from '@jcm/design-system';
import { Popover } from 'antd';
import { memo, useState } from 'react';

import { GetNotificacoesReturnType } from 'api';
import { UseQueryFn } from 'queries';

import styles from './index.module.scss';

type NotificationsPopoverProps = {
	children: React.ReactNode;
	data: GetNotificacoesReturnType[];
	isOpen: boolean;
	onOpenChange: (newValue: boolean) => void;
};
const NotificationsPopover = memo<NotificationsPopoverProps>(({ data, children, isOpen, onOpenChange }) => {
	const { colors, shapes } = useTheme();

	const content = (
		<Flex gap={shapes.sizes.medium} vertical>
			<Flex gap={shapes.sizes.extraSmall} vertical>
				<Card
					id='avatar-popover-content-card'
					variant='default'
					style={{
						padding: shapes.sizes.small,
						minWidth: '150px',
						maxHeight: '50vh',
						overflowX: 'auto',
					}}
				>
					<Flex gap={shapes.sizes.large} style={{ width: '100%' }} vertical>
						{data.length === 0 && (
							<Typography.Body
								size='medium'
								variant='default'
								style={{ userSelect: 'none', margin: shapes.sizes.small }}
							>
								Não há notificações!
							</Typography.Body>
						)}

						{data.map((item) => {
							const isIconValid = !!(item.icon && Object.keys(Icons).includes(item.icon));
							const Icon = isIconValid ? Icons[item.icon as keyof Icons] : Icons.Notifications;

							return (
								<Badge dot={item.new} key={JSON.stringify(item)} className={styles.badge}>
									<Button
										className={styles.btn}
										variant='default'
										type='text'
										icon={<Icon variant='outlined' />}
										style={{ width: '100%' }}
									>
										{item.title}
									</Button>
								</Badge>
							);
						})}
					</Flex>
				</Card>
			</Flex>
		</Flex>
	);

	return (
		<Popover
			overlayClassName={styles.popover}
			color={colors.secondaryContainer}
			placement='bottomLeft'
			trigger='click'
			arrow={true}
			content={content}
			open={isOpen}
			onOpenChange={onOpenChange}
		>
			{children}
		</Popover>
	);
});

export type NotificationsIconButtonProps = {
	id?: string;
	isMobile?: boolean;
	useQueryFn: UseQueryFn<GetNotificacoesReturnType[]>;
};
export const NotificationsIconButton = memo<NotificationsIconButtonProps>(({ id, isMobile, useQueryFn }) => {
	const [isOpen, setIsOpen] = useState(false);

	const { data, isFetching } = useQueryFn();

	return (
		<NotificationsPopover data={data ?? []} isOpen={isOpen} onOpenChange={setIsOpen}>
			<Badge count={data?.filter((item) => item.new).length} dot={isMobile} offset={isMobile ? [-5, 5] : [0, 0]}>
				<Button
					id={id}
					type='text'
					variant='default'
					icon={<Icons.Notifications variant='outlined' fill={isOpen} />}
					loading={isFetching}
					circle
				/>
			</Badge>
		</NotificationsPopover>
	);
});
