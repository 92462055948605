import 'dayjs/locale/pt-br';

import { DatePicker as DSDatePicker, IDatePickerProps as IDSDatePickerProps } from '@jcm/design-system';
import { DatePickerProps } from 'antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { Dayjs } from 'dayjs';
import { memo } from 'react';

import { DateFormatter } from 'lib';

import styles from './index.module.scss';

// TODO - Corrigir essas props no design system
interface IDatePickerProps extends IDSDatePickerProps {
	onChange?: DatePickerProps['onChange'];
}
export const DatePicker = memo<IDatePickerProps>(({ className, ...props }) => {
	return (
		<DSDatePicker
			className={`${styles.datePicker} ${className || ''}`}
			locale={locale}
			format={(date: Dayjs) => DateFormatter.format(date.toDate(), 'DD/MM/YYYY')}
			{...props}
		/>
	);
});
