import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';

import { GetParticipacoesPlanosReturnType } from 'api';
import { useKeycloakContext } from 'context';
import { TipoParticipanteEnum } from 'lib';
import { useParticipacoesPlanosQuery } from 'queries/participante';

export interface IParticipanteIdentityContext {
	participacaoAtiva?: GetParticipacoesPlanosReturnType;

	idParticipacao: string | number | null;
	changeIdParticipacao: (idParticipacao: string | number) => void;

	tipoParticipante: TipoParticipanteEnum | null;
	changeTipoParticipante: (tipo: TipoParticipanteEnum) => void;
}

// eslint-disable-next-line
const ParticipanteIdentityContext = createContext<IParticipanteIdentityContext>(undefined!);

export const useParticipanteIdentityContext = () => useContext(ParticipanteIdentityContext);

type Props = { children: React.ReactNode };
/**
 * Componente responsável por disponibilizar informações relacionadas à identidade do participante
 */
export const ParticipanteIdentityContextProvider: React.FC<Props> = ({ children }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [idParticipacao, setIdParticipacao] = useSessionStorage<string | number | null>(
		'participanteIdentityContextIdParticipacao',
		null,
	);
	const [tipoParticipante, setTipoParticipante] = useState<TipoParticipanteEnum | null>(null);

	const { data } = useParticipacoesPlanosQuery(idParticipacao);

	const participacaoAtiva = useMemo(() => {
		return data?.find((par) => par.participacaoId === Number(idParticipacao));
	}, [data, idParticipacao]);

	const changeIdParticipacao = useCallback(
		(id: string | number) => {
			setIdParticipacao(id);
			setSearchParams({ idParticipacao: id.toString() });
		},
		[setIdParticipacao, setSearchParams],
	);

	const clearIdParticipacao = useCallback(() => setIdParticipacao(null), [setIdParticipacao]);

	const changeTipoParticipante = useCallback(
		(tipo: TipoParticipanteEnum) => setTipoParticipante(tipo),
		[setTipoParticipante],
	);

	// Atualiza o id de participação da Session Storage caso a url seja alterada
	useEffect(() => {
		const idParticipacaoParams = searchParams.get('idParticipacao');

		if (idParticipacaoParams) changeIdParticipacao(idParticipacaoParams);
	}, [searchParams, changeIdParticipacao]);

	// Limpa id de participação da Session Storage caso a página atual não tenha um
	// Também redireciona o usuário pra página admin, caso o admin não especifique um idParticipacao
	const navigate = useNavigate();
	const { isAdmin } = useKeycloakContext();
	useEffect(() => {
		const idParticipacaoParams = searchParams.get('idParticipacao');

		if (!idParticipacaoParams && isAdmin) {
			clearIdParticipacao();
			navigate('/admin');
		}
	}, [searchParams, isAdmin, navigate, clearIdParticipacao]);

	// Sempre que a participacaoAtiva mudar, atualiza os states
	useEffect(() => {
		if (!participacaoAtiva) return;

		changeIdParticipacao(participacaoAtiva.participacaoId);
		changeTipoParticipante(participacaoAtiva.tipoParticipante);
	}, [participacaoAtiva, changeIdParticipacao, changeTipoParticipante]);

	// Seleciona a primeira participação caso:
	// - idParticipacao seja nulo
	// OU
	// - não exista participação ativa
	useEffect(() => {
		if (!data?.length) return;

		if (idParticipacao === null || !participacaoAtiva) changeIdParticipacao(data[0].participacaoId);
	}, [data, idParticipacao, participacaoAtiva, changeIdParticipacao, changeTipoParticipante]);

	const context = useMemo<IParticipanteIdentityContext>(() => {
		return { participacaoAtiva, idParticipacao, changeIdParticipacao, tipoParticipante, changeTipoParticipante };
	}, [participacaoAtiva, idParticipacao, changeIdParticipacao, tipoParticipante, changeTipoParticipante]);

	return <ParticipanteIdentityContext.Provider value={context}>{children}</ParticipanteIdentityContext.Provider>;
};
