import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Range } from 'lib/helpers';

dayjs.extend(utc);
dayjs.extend(tz);

export type FullDateFormat = 'MMM/YYYY' | 'DD/MM/YYYY' | 'MM/YYYY';
export type MonthFormat = 'MM' | 'MMM' | 'MMMM';

export enum MesesAbreviadosEnum {
	Jan = 1,
	Fev,
	Mar,
	Abr,
	Mai,
	Jun,
	Jul,
	Ago,
	Set,
	Out,
	Nov,
	Dez,
}

export enum MesesEnum {
	Janeiro = 1,
	Fevereiro,
	Março,
	Abril,
	Maio,
	Junho,
	Julho,
	Agosto,
	Setembro,
	Outubro,
	Novembro,
	Dezembro,
}

/**
 * Classe responsável por abrigar métodos relacionados a tratamento de datas (`Date` e `dayjs.Dayjs`) e exibição das mesmas.
 */
export class DateFormatter {
	/**
	 * Gera uma data da lib `dayjs` no fuso horário UTC
	 */
	static toDayjs = (dateStr?: string, timezone = 'America/Sao_Paulo') => dayjs.tz(dateStr, timezone);
	/**
	 * Gera uma data da lib `dayjs` no fuso horário UTC e a converte para `Date`
	 */
	static toDate = (dateStr?: string, timezone = 'America/Sao_Paulo') => dayjs.tz(dateStr, timezone).toDate();

	static format = (date: Date, format?: FullDateFormat) => {
		if (!format) return date.toLocaleDateString();

		switch (format) {
			case 'MMM/YYYY': {
				const mesMMM = date.getMonth() + 1;
				const anoMMM = date.getFullYear();
				const nomeMesMMM = MesesEnum[mesMMM];
				return `${nomeMesMMM}/${anoMMM}`;
			}

			case 'DD/MM/YYYY': {
				const dia = date.getDate().toString().padStart(2, '0');
				const mesAbreviado = (date.getMonth() + 1).toString().padStart(2, '0');
				const anoCompleto = date.getFullYear();
				return `${dia}/${mesAbreviado}/${anoCompleto}`;
			}

			case 'MM/YYYY': {
				const mesMM = (date.getMonth() + 1).toString().padStart(2, '0');
				const anoMM = date.getFullYear();
				return `${mesMM}/${anoMM}`;
			}

			default:
				throw new Error(`Formato não suportado: ${format}`);
		}
	};

	static formatMonth = (month: Range<1, 13>, format: MonthFormat = 'MMMM') => {
		switch (format) {
			case 'MM':
				return month.toLocaleString('pt-BR', { minimumIntegerDigits: 2 });
			case 'MMM':
				return MesesAbreviadosEnum[month];
			case 'MMMM':
				return MesesEnum[month];
		}
	};
}
