import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary as Boundary } from 'react-error-boundary';

import { ErrorComponent } from './ErrorComponent';

type FallbackProps<TError extends Error> = { error: TError; resetErrorBoundary: (...args: unknown[]) => void };

function Fallback<T extends Error>({ error, resetErrorBoundary }: FallbackProps<T>) {
	return <ErrorComponent error={error} onClick={resetErrorBoundary} />;
}

export interface IErrorBoundaryProps<TError extends Error> {
	children?: React.ReactNode;
	FallbackComponent?: React.ComponentType<FallbackProps<TError>>;
}

export function ErrorBoundary<T extends Error = Error>({
	children,
	FallbackComponent = Fallback<T>,
}: IErrorBoundaryProps<T>) {
	const { reset } = useQueryErrorResetBoundary();

	return (
		<Boundary onReset={reset} FallbackComponent={FallbackComponent}>
			{children}
		</Boundary>
	);
}
