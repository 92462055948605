import { Avatar, Flex, Icons, Skeleton, Surface, Tag, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { GetParticipantesReturnType } from 'api';
import { useNavigate } from 'react-router-dom';
import { RotasAdmin } from 'routes/RotasAdmin';

enum TipoVinculoParticipacaoEnum {
	'Vinculado à Patrocinadora' = 'verde',
	'Autopatrocinado' = 'roxo',
	'BPD' = 'laranja',
	'Em Benefício' = 'primary',
	'Participação Encerrada' = 'amarelo',
	'(Em Retirada) Ativo' = 'magenta',
	'(Em Retirada) Auto-Financiado' = 'magenta',
	'(Em Retirada) BPD' = 'magenta',
	'Desligado da Patrocinadora' = 'amarelo',
	'BPD - Presumido' = 'laranja',
}

export type CardProps = {
	id?: string;
	className?: string;

	data: GetParticipantesReturnType;
	onClick: (participante: GetParticipantesReturnType) => void;
};

export const Card = memo<CardProps>(({ data, onClick, ...props }) => {
	const { colors, shapes } = useTheme();
	const navigate = useNavigate();

	return (
		<Surface variant='bright' level={1} style={{ height: '100%', padding: '1rem' }} {...props}>
			<Flex flex={1} vertical gap={'0.5rem'}>
				<Flex justify='space-between'>
					<Typography.Body size='large' variant='primary' style={{ fontWeight: 500 }}>
						{data.empregador}
					</Typography.Body>
					<Flex
						align='center'
						justify='center'
						style={{ border: `1px solid ${colors.outline}`, borderRadius: shapes.sizes.extraSmall, height: 26 }}
					>
						<Typography.Title
							onClick={() => navigate(RotasAdmin.get('dadosCadastrais') + '/' + data.participacaoId)}
							size='large'
							variant='primary'
							style={{ cursor: 'pointer', padding: '0.25rem', width: 26 }}
						>
							<Icons.Person variant='outlined' style={{ fontSize: 18, height: '100%' }} />
						</Typography.Title>
						<div style={{ backgroundColor: colors.outline, height: '100%', width: 1 }}></div>
						<Typography.Title
							size='large'
							variant='primary'
							style={{ cursor: 'pointer', padding: '0.25rem', width: 26 }}
						>
							<Icons.Visibility
								variant='outlined'
								style={{ fontSize: 18, height: '100%' }}
								onClick={() => onClick(data)}
							/>
						</Typography.Title>
					</Flex>
				</Flex>
				<Flex gap={'0.75rem'}>
					<Flex gap={'0.5rem'} vertical style={{ minWidth: 72 }} align='center'>
						<Avatar
							icon={<Icons.Person variant='outlined' />}
							style={{ backgroundColor: colors.tertiary, color: colors.onTertiary }}
						/>
						<Typography.Body size='small' variant='default' style={{ fontWeight: 500 }}>
							{data.matricula?.toString()}
						</Typography.Body>
					</Flex>
					<Flex vertical flex={1} gap={'0.5rem'}>
						<Typography.Body size='large' variant='default' style={{ color: colors.onSurfaceVariant }}>
							{data.nome}
						</Typography.Body>
						<Typography.Body size='small' variant='primary' style={{ color: colors.onSurfaceVariant }}>
							{data.cpf?.toString()}
						</Typography.Body>
						<Typography.Body size='small' variant='primary' style={{ color: colors.onSurfaceVariant }}>
							{data.plano}
						</Typography.Body>
						<Typography.Body size='small' variant='primary' style={{ color: colors.onSurfaceVariant }}>
							Situação: {data.situacaoParticipacao}
						</Typography.Body>
						{data.tipoVinculoParticipacao && (
							<Tag
								variant={
									TipoVinculoParticipacaoEnum[data.tipoVinculoParticipacao as keyof typeof TipoVinculoParticipacaoEnum]
								}
								style={{ borderRadius: shapes.sizes.small, width: 'fit-content' }}
							>
								{data.tipoVinculoParticipacao}
							</Tag>
						)}
					</Flex>
				</Flex>
			</Flex>
		</Surface>
	);
});

export type CardSkeletonProps = {
	id?: string;
	className?: string;
};

export const CardSkeleton = memo<CardSkeletonProps>(({ ...props }) => {
	return (
		<Surface variant='bright' level={1} style={{ height: '100%', padding: '1rem' }} {...props}>
			<Flex flex={1} vertical gap={'0.5rem'}>
				<Flex justify='space-between'>
					<Skeleton.Input active size={'small'} />
					<Skeleton.Input active size={'small'} />
				</Flex>
				<Flex gap={'0.75rem'}>
					<Flex gap={'0.5rem'} vertical style={{ minWidth: 72 }} align='center'>
						<Skeleton.Avatar active size={'large'} />
						<Skeleton.Input active size={'small'} />
					</Flex>
					<Flex vertical flex={1} gap={'0.5rem'}>
						<Skeleton.Input active size={'small'} />
						<Skeleton.Input active size={'small'} />
						<Skeleton.Input active size={'small'} />
						<Skeleton.Input active size={'small'} />
						<Skeleton.Input active size={'small'} />
					</Flex>
				</Flex>
			</Flex>
		</Surface>
	);
});
