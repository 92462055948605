import { ITableProps, Table, useTheme } from '@jcm/design-system';

import styles from './CustomTitleTable.module.scss';

export interface ICustomTitleTableProps<T> {
	id?: string;
	title: React.ReactNode;
	columns: ITableProps<T>['columns'];
	dataSource: ITableProps<T>['dataSource'];
	rowKey?: ITableProps<T>['rowKey'];
	rowClassName?: string | ITableProps<T>['rowClassName'];
	loading?: ITableProps<T>['loading'];
	containerClassName?: string;
	wrapperClassName?: string;
	headerClassName?: string;
	headerStyle?: React.CSSProperties;
	tableVariant?: ITableProps<T>['variant'];
	tableStyle?: React.CSSProperties;
}
export const CustomTitleTable = <T,>({
	title,
	columns,
	dataSource,
	loading,
	id,
	rowKey = 'uuid',
	rowClassName = undefined,
	containerClassName = '',
	headerClassName = '',
	wrapperClassName = '',
	headerStyle = {},
	tableVariant = 'default',
	tableStyle = {},
}: ICustomTitleTableProps<T>) => {
	const { shapes, colors } = useTheme();

	return (
		<div className={containerClassName} id={id}>
			<div
				className={headerClassName}
				style={{
					margin: 0,
					display: 'block',
					padding: shapes.sizes.small,
					borderTopRightRadius: shapes.sizes.small,
					borderTopLeftRadius: shapes.sizes.small,
					...headerStyle,
				}}
			>
				{title}
			</div>

			<div className={`${styles.wrapper} ${wrapperClassName}`}>
				<div style={{ backgroundColor: colors.surface }}>
					<Table<T>
						variant={tableVariant}
						dataSource={dataSource}
						columns={columns}
						rowKey={rowKey}
						rowClassName={rowClassName}
						loading={loading}
						style={{ overflowX: 'auto', ...tableStyle }}
					/>
				</div>
			</div>
		</div>
	);
};
