import { useEffect, useState } from 'react';

import { ValidationFields, ValidationResult, Validator } from 'lib';

export type ValidationMessagesType<TData> = Partial<Record<keyof TData, string>>;
export type UseValidationValidateCb<TData> = (result: ValidationResult<TData>) => ValidationMessagesType<TData>;
export type UseValidationArgs<TData> = {
	validator: Validator<TData>;
	fields: ValidationFields<TData>;

	onValidate?: UseValidationValidateCb<TData>;
};
/**
 * Hook que age como um helper para validação de campos + exibição de mensagens de erro.
 *
 * SEMPRE MEMOIZE OS PARÂMETROS DESSE HOOK!
 * @returns
 */
export const useValidation = <TData>({ validator, fields, onValidate }: UseValidationArgs<TData>) => {
	const [messages, setMessages] = useState<ValidationMessagesType<TData>>({});
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		const result = validator.validateFields(fields);
		setIsValid(result.isValid);

		if (onValidate) setMessages(onValidate?.(result));
	}, [validator, fields, onValidate]);

	return { messages, isValid };
};
