import { Col, Row, Typography, useTheme, Flex, Checkbox } from '@jcm/design-system';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useBeneficiariosQuery } from 'queries/participante';
// TODO - Alterar para a API correta quando ela existir.

import { DadosCadastraisSection } from '../../cards';
import { GetBeneficiariosReturnType } from 'api';
import { DateFormatter } from 'lib';

const Beneficiario = memo<{ dadosBeneficiario: GetBeneficiariosReturnType; index: number }>(({ dadosBeneficiario }) => {
	const { colors } = useTheme();

	return (
		<Flex
			style={{
				gap: '1rem',
				flexDirection: 'column',
				backgroundColor: colors.surfaceBright,
				borderRadius: 12,
				padding: '1rem',
			}}
		>
			<Row>
				<Col xl={6} md={12} sm={24} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
					<Typography.Title size='medium' strong style={{ color: colors.tertiary }}>
						{dadosBeneficiario.nome}
					</Typography.Title>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col xl={6} md={12} sm={24} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
					<Typography.Title size='small' strong style={{ color: colors.tertiary }}>
						CPF
					</Typography.Title>
					<Typography.Body>{dadosBeneficiario.cpf}</Typography.Body>
				</Col>
				<Col xl={6} md={12} sm={24} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
					<Typography.Title size='small' strong style={{ color: colors.tertiary }}>
						Data de nascimento
					</Typography.Title>
					<Typography.Body>
						{DateFormatter.format(DateFormatter.toDate(dadosBeneficiario.dataNascimento))}
					</Typography.Body>
				</Col>
				<Col xl={6} md={12} sm={24} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
					<Typography.Title size='small' strong style={{ color: colors.tertiary }}>
						Grau de parentesco
					</Typography.Title>
					<Typography.Body>{dadosBeneficiario.parentesco}</Typography.Body>
				</Col>
				<Col xl={6} md={12} sm={24} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
					<Typography.Title size='small' strong style={{ color: colors.tertiary }}>
						Percentual de recebimento
					</Typography.Title>
					<Typography.Body>{dadosBeneficiario.percentualRecebimento}</Typography.Body>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col xl={6} md={12} sm={24} style={{ display: 'flex', gap: '.25rem', alignItems: 'center' }}>
					<Checkbox checked={!!dadosBeneficiario?.dependenteLegalSn}></Checkbox>
					<Typography.Body>Dependente legal</Typography.Body>
				</Col>
				<Col xl={6} md={12} style={{ display: 'flex', gap: '.25rem', alignItems: 'center' }}>
					<Checkbox checked={!!dadosBeneficiario?.dependenteImpRendaSn}></Checkbox>
					<Typography.Body>Dependente imp. renda</Typography.Body>
				</Col>
			</Row>
		</Flex>
	);
});

export const Beneficiarios: React.FC = () => {
	// Extrair id do participante da URL
	// TODO - Alterar para a API correta quando ela existir.
	const params = useParams<{ participacaoId: string }>();
	const { data } = useBeneficiariosQuery(params.participacaoId as string);

	return (
		<DadosCadastraisSection title='Beneficiários'>
			<Row gutter={[16, 16]}>
				{data?.map((dadosBeneficiario, index) => (
					<Col key={index} xs={24} style={{ gap: 250 }}>
						<Beneficiario dadosBeneficiario={dadosBeneficiario} index={index} />
					</Col>
				))}
			</Row>
		</DadosCadastraisSection>
	);
};
