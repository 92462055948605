import { memo } from 'react';

import { useIsMobile } from 'hooks';

import { SeletorParticipacaoDesktop } from './Desktop';
import { SeletorParticipacaoMobile } from './Mobile';

export interface ISeletorParticipacaoProps {
	id?: string;
	className?: string;

	idParticipacaoAtiva?: number;
	onParticipacaoChange: (value: number) => void;
}
export const SeletorParticipacao = memo<ISeletorParticipacaoProps>((props) => {
	const isMobile = useIsMobile();

	const Component = isMobile ? SeletorParticipacaoMobile : SeletorParticipacaoDesktop;

	return <Component {...props} />;
});
